import { updateValoracion } from '../../api/valoraciones/updateValoracion';
import createApiModule from '../createApiModule';

const updateValoracionModule = createApiModule({
  name: 'updateValoracion',
  apiMethod: updateValoracion,
  parse: (data) => data,
  onSuccess: () => {},
  onError: () => ({
    message: 'Se ha producido un error registrando su valoración',
  }),
  onValidationError: () => ({
    message: 'Se ha producido un error registrando su valoración',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
  reset,
} = updateValoracionModule.slice.actions;

export default updateValoracionModule;

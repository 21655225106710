import { RootState } from '../store';

/******************** USERS **************************************/
export const selectUsersSlice = (state: RootState) => state.users;
export const selectUsers = (state: RootState) =>
  selectUsersSlice(state).data.data;
export const selectUsersLoading = (state: RootState) =>
  selectUsersSlice(state).loading;
export const selectUsersPageInfo = (state: RootState) =>
  selectUsersSlice(state).data.meta;

/******************** REGISTER USER **************************************/
export const selectNewUser = (state: RootState) => state.registerUser;
export const selectNewUserLoading = (state: RootState) =>
  selectNewUser(state).loading;
export const selectNewUserErrors = (state: RootState) =>
  selectNewUser(state).errors;

/******************** UPDATE USER **************************************/
export const selectUpdateUser = (state: RootState) => state.updateUser;
export const selectUpdateUserLoading = (state: RootState) =>
  selectNewUser(state).loading;
export const selectUpdateUserErrors = (state: RootState) =>
  selectNewUser(state).errors;

/******************** USER **************************************/
export const selectUserSlice = (state: RootState) => state.user;
export const selectUser = (state: RootState) => selectUserSlice(state).data;
export const selectUserLoading = (state: RootState) =>
  selectUserSlice(state).loading;
export const selectUserErrors = (state: RootState) =>
  selectUserSlice(state).errors;
/******************** DELETE USER *****************************/
export const selectDeleteUser = (state: RootState) => state.deleteUser.user;

export const selectDeleteUserActive = (state: RootState) =>
  state.deleteUser.active;

export const selectDeleteUserLoading = (state: RootState) =>
  state.deleteUser.loading;

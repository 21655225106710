import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { Result, validatedApiCall } from '../api';
import { DonacionesDTO } from './getDonaciones';

export interface GetDonacionesOwnDTO {
  page?: number;
  size?: number;
}

export const getOwnDonaciones = async (
  data: GetDonacionesOwnDTO
): Promise<Result<DonacionesDTO, APIValidationError>> => {
  return validatedApiCall<DonacionesDTO>(
    `/api/donaciones/own?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};

import { lazy } from 'react';

const NotFound = lazy(() => import('./NotFound'));

const NotFoundRoutes = [
  {
    path: '*',
    element: <NotFound />,
  },
];

export default NotFoundRoutes;

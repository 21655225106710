import { APIValidationError } from '../../types/api/api';
import { Certificado } from '../../types/certificado/certificado';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';

export const getCertificadoURL = async (
  id: UUID
): Promise<Result<Certificado, APIValidationError>> => {
  return validatedApiCall<Certificado>(`/api/certificados/${id}`, {
    method: 'GET',
    auth: true,
  });
};

import { bajaVoluntario as callBajaVoluntario } from '../../api/voluntarios/bajaVoluntario';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { getVoluntarioEstado } from './voluntarioEstadoModule';

const voluntarioBajaModule = createApiModule({
  name: 'voluntarioBaja',
  apiMethod: callBajaVoluntario,
  onSuccess: (success) => [
    showSnackBar({
      severity: 'success',
      message: `Hemos dado de baja su voluntariado`,
      path: '/',
    }),
    getVoluntarioEstado({}),
  ],
  onError: () => ({
    message: 'Se ha producido un error aplicando la baja de voluntario',
  }),
});

export const {
  start: bajaVoluntario,
  success: bajaVoluntarioOk,
  error: bajaVoluntarioKo,
  loading: bajaVoluntarioLoading,
} = voluntarioBajaModule.slice.actions;

export const { sagas } = voluntarioBajaModule;

export default voluntarioBajaModule;

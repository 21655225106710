import { registerEntidad } from '../../api/entidades/registerEntidad';
import createApiModule from '../createApiModule';

const registerEntidadModule = createApiModule({
  name: 'registerEntidad',
  apiMethod: registerEntidad,
  onSuccess: (entidad) => ({
    message: `La entidad ${entidad.nombre} se ha creado correctamente.`,
    path: '/entidades',
  }),
  onError: () => ({
    message: 'Error creando la entidad',
  }),
  onValidationError: () => ({
    message: 'Error creando la entidad',
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerEntidadModule.slice.actions;

export const { sagas } = registerEntidadModule;

export default registerEntidadModule;

import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { createSession, getSession } from '../../../api/stripe/stripeSession';
import { Await } from '../../../types/api/api';
import { StripeSessionId, StripeSessionRequest } from '../../../types/stripe';
import getStripe from '../../../utils/get-stripe';
import checkoutSessionSlice from './checkoutSessionSlice';
import { isApiError } from '../../../api/api';

function* createNewSession(
  action: PayloadAction<StripeSessionRequest>
): Generator<any, void, any> {
  try {
    const result = (yield call(createSession, action.payload)) as Await<
      ReturnType<typeof createSession>
    >;
    switch (result.type) {
      case 'ok':
        yield put(checkoutSessionSlice.actions.createSessionOk(result.value));
        if (action.payload.redirectToCheckout) {
          getStripe().then((stripe) => {
            stripe!
              .redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as parameter here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: result.value.id,
              })
              .then(({ error }) => {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `error.message`.
                console.warn(error.message);
              });
          });
        }
        return;
      case 'validation-error':
        yield put(checkoutSessionSlice.actions.createSessionKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(checkoutSessionSlice.actions.createSessionKo(e));
    }
    throw e;
  }
}

function* loadSession(
  action: PayloadAction<StripeSessionId>
): Generator<any, void, any> {
  try {
    const result = (yield call(getSession, action.payload)) as Await<
      ReturnType<typeof getSession>
    >;
    switch (result.type) {
      case 'ok':
        yield put(checkoutSessionSlice.actions.loadSessionOk(result.value));
        return;
      case 'validation-error':
        yield put(checkoutSessionSlice.actions.loadSessionKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(checkoutSessionSlice.actions.loadSessionKo(e));
    }
    throw e;
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    checkoutSessionSlice.actions.createSession.type,
    createNewSession
  ),
  takeLatest<PayloadAction<never>>(
    checkoutSessionSlice.actions.loadSession.type,
    loadSession
  ),
];

export default sagas;

import { registerPueblo } from '../../api/pueblos/registerPueblo';
import createApiModule from '../createApiModule';

const registerPuebloModule = createApiModule({
  name: 'registerPueblo',
  apiMethod: registerPueblo,
  onSuccess: (pueblo) => ({
    message: `El municipio ${pueblo.poblacion} se ha creado correctamente.`,
    path: '/pueblos',
  }),
  onError: () => ({
    message: 'Error creando el municipio',
  }),
  onValidationError: () => ({
    message: 'Error creando el municipio',
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerPuebloModule.slice.actions;

export const { sagas } = registerPuebloModule;

export default registerPuebloModule;

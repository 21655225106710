import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { Provincia } from '../../types/provincia/provincia';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Pueblo } from '../../types/pueblos/pueblo';

export interface GetPuebloDTO {
  page?: number;
  size?: number;
  buscar?: string | '';
  provincia?: Provincia | '';
}

export interface PueblosDTO {
  data: Pueblo[];
  links: LinksPage;
  meta: PageInfo;
}

export const getPueblos = async (
  data: GetPuebloDTO
): Promise<Result<PueblosDTO, APIValidationError>> => {
  return validatedApiCall<PueblosDTO>(
    `/api/pueblos?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};

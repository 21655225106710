import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Solicitud } from '../../types/solicitud/solicitud';
import { PAGE, SIZE, UUID } from '../../types/standard';
import { TipoSolicitud } from '../../types/solicitud/tipoSolicitud.d';
import { EstadoSolicitud } from '../../types/solicitud/estadoSolicitud.d';

export interface GetSolicitudesDTO {
  page?: PAGE;
  size?: SIZE;
  tipo?: TipoSolicitud | '';
  estado?: EstadoSolicitud | '';
  user_id?: UUID;
  voluntario?: string;
}

export interface SolicitudDTO {
  data: Solicitud[];
  links: LinksPage;
  meta: PageInfo;
}

export const getSolicitudes = async (
  data: GetSolicitudesDTO
): Promise<Result<SolicitudDTO, APIValidationError>> => {
  return validatedApiCall<SolicitudDTO>(
    `/api/solicitudes?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};

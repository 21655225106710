import { updateVecino } from '../../api/vecinos/updateVecino';
import createApiModule from '../createApiModule';

const updateVecinoModule = createApiModule({
  name: 'updateVecino',
  apiMethod: updateVecino,
  onSuccess: (user) => ({
    message: `${user.nombre} ${user.apellido1} ${user.apellido2} se ha modificado correctamente.`,
    path: '/vecinos',
  }),
  onError: () => ({
    message: 'Error al modificar el vecino/a',
  }),
  onValidationError: () => ({
    message: 'Error al modficar el vecino/a',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateVecinoModule.slice.actions;

export default updateVecinoModule;

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectPasswordRestrictionRestricted } from '../../store/auth/restrictions/password/selectors';
import { RootState } from '../../store/store';
import { Permission, PermissionEnum } from '../../types/auth/permissions.d';
import { Role, RoleEnum } from '../../types/auth/roles.d';

export const allRoles = Object.keys(RoleEnum) as Role[];
export const allPermissions = Object.keys(PermissionEnum) as Permission[];

const LoginRestrictions: React.FC = () => {
  const location = useLocation();
  const { password } = useSelector((state: RootState) => ({
    password: selectPasswordRestrictionRestricted(state),
  }));

  return !password ? (
    <Outlet />
  ) : (
    <Navigate to={'/nueva_contrasena'} state={{ from: location }} replace />
  );
};

export default LoginRestrictions;

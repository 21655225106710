import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';
import { APIValidationError } from '../../types/api/api';
import { Solicitud } from '../../types/solicitud/solicitud';

export const getSolicitudById = async (
  id: UUID
): Promise<Result<Solicitud, APIValidationError>> => {
  return validatedApiCall<Solicitud>(`/api/solicitudes/${id}`, {
    method: 'GET',
    auth: true,
  });
};

import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';
import { Valoracion } from '../../types/valoracion/valoracion';
import { Result, validatedApiCall } from '../api';

export const getValoracion = async (
  valoracion_id: UUID
): Promise<Result<Valoracion, APIValidationError>> => {
  return validatedApiCall<Valoracion>(
    `/api/solicitudes/valoracion/${valoracion_id}`,
    {
      method: 'GET',
      auth: false,
    }
  );
};

import { RootState } from '../../store';

const selectVecinoState = (state: RootState) => state.registerVecino;

export const selectFormData = (state: RootState) =>
  selectVecinoState(state).form_data;

export const selectVecinoLoading = (state: RootState) =>
  selectVecinoState(state).loading_register;

export const selectVecinoVecino = (state: RootState) =>
  state.registerVecino.vecino;

export const selectVecinoErrors = (state: RootState) =>
  selectVecinoState(state).errors;

export const selectVecinoSocio = (state: RootState) =>
  selectVecinoState(state).socio;

export const selectVecinoIsSocio = (state: RootState) =>
  selectVecinoSocio(state).es_socio;

export const selectSelectedPrice = (state: RootState) =>
  state.registerVecino.socio.datos_socio
    ? state.registerVecino.socio.datos_socio.price
    : null;

export const selectVecinoIsSocioCooperativa = (state: RootState) =>
  selectVecinoSocio(state).datos_socio?.socio_tipo === 'cooperativa';

export const selectVecinoIsSocioPueblo = (state: RootState) =>
  selectVecinoSocio(state).datos_socio?.socio_tipo === 'pueblo';

export const selectVecinoSetupIntent = (state: RootState) =>
  selectVecinoSocio(state).setup_intent;

export const selectVecinoPaymentMethod = (state: RootState) =>
  selectVecinoSocio(state).payment_method;

export const selectVecinoSocioLoading = (state: RootState) =>
  selectVecinoSocio(state).loading_setup_intent;

export const selectVecinoSocioErrors = (state: RootState) =>
  selectVecinoSocio(state).errors;

export const selectMustValidatePaymentMethod = (state: RootState) => {
  if (!selectVecinoIsSocio(state)) {
    return false;
  }
  return selectVecinoSetupIntent(state)?.status !== 'succeeded';
};

export const selectVecinoRegisterCompleted = (state: RootState) => {
  return selectVecinoState(state).registro_completado;
};

export const selectVecinoIsVoluntario = (state: RootState) =>
  selectVecinoState(state).voluntario;

export const selectVecinoIsPersonaCuidadora = (state: RootState) =>
  selectVecinoState(state).persona_cuidadora;

export const selectRegisterStep = (state: RootState) =>
  selectVecinoState(state).step;

/**                         Restricciones baja                       */
export const selectBajaRestrictions = (state: RootState) =>
  state.bajaRestrictions.data;

export const selectBajaRestrictionsLoading = (state: RootState) =>
  state.bajaRestrictions.loading;

/**                         Baja                                    */
export const selectBajaLoading = (state: RootState) => state.baja.loading;

import { RootState } from '../store';

/********************PUEBLO **************************************/
export const selectPuebloSlice = (state: RootState) => state.pueblo;

export const selectPueblo = (state: RootState) => selectPuebloSlice(state).data;

export const selectPuebloErrors = (state: RootState) =>
  selectPuebloSlice(state).errors;

export const selectPuebloLoading = (state: RootState) =>
  selectPuebloSlice(state).loading;

/********************LIST PUEBLOS *********************************/
export const selectPueblosSlice = (state: RootState) => state.pueblos;

export const selectPueblos = (state: RootState) =>
  selectPueblosSlice(state).data.data;

export const selectPageInfo = (state: RootState) =>
  selectPueblosSlice(state).data.meta;

export const selectPueblosErrors = (state: RootState) =>
  selectPueblosSlice(state).errors;

export const selectPueblosLoading = (state: RootState) =>
  selectPueblosSlice(state).loading;

/******************** REGISTER PUEBLO *****************************/
export const selectRegisterPueblo = (state: RootState) => state.registerPueblo;

export const selectRegisterPuebloLoading = (state: RootState) =>
  selectRegisterPueblo(state)?.loading;

export const selectRegisterPuebloErrors = (state: RootState) =>
  selectRegisterPueblo(state)?.errors;

/******************** DELETE PUEBLO *****************************/
export const selectDeletePueblo = (state: RootState) =>
  state.deletePueblo.selectedPueblo;

export const selectDeletePuebloActive = (state: RootState) =>
  state.deletePueblo.active;

export const selectDeletePuebloLoading = (state: RootState) =>
  state.deletePueblo.loading;

/******************** UPDATE PUEBLO *****************************/

export const selectUpdatePuebloPueblo = (state: RootState) =>
  state.updatePueblo.pueblo;

export const selectUpdatePuebloActive = (state: RootState) =>
  state.updatePueblo.active;

export const selectUpdatePuebloLoading = (state: RootState) =>
  state.updatePueblo.loading;

export const selectUpdatePuebloErrors = (state: RootState) =>
  state.updatePueblo.errors;

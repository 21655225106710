import { DonacionesDTO } from '../../api/donaciones/getDonaciones';
import { getOwnDonaciones } from '../../api/donaciones/getOwnDonaciones';
import { GenericState } from '../../types/apiModule';
import { Donacion } from '../../types/donaciones/donaciones';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import createApiModule from '../createApiModule';

export interface DonacionesModule extends GenericState<unknown> {
  data: DonacionesDTO;
}

const initialState: DonacionesModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Donacion[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listDonacionesOwnModule = createApiModule({
  name: 'listOwnDonaciones',
  apiMethod: getOwnDonaciones,
  initialState,
  parse: (donaciones) => donaciones,
  onSuccess: () => {},
  onError: () => ({
    message: 'Error listando las donaciones',
  }),
  onValidationError: () => {},
});

export const {
  start: listOwnDonaciones,
  success: listOwnDonacionesOk,
  error: listOwnDonacionesKo,
  refresh: listOwnDonacionesRefresh,
  reset: listOwnDonacionesReset,
} = listDonacionesOwnModule.slice.actions;

export default listDonacionesOwnModule;

import { PayloadAction } from '@reduxjs/toolkit';
import { deleteVoluntario as deleteVoluntarioApi } from '../../api/voluntarios/deleteVoluntario';
import { GenericState } from '../../types/apiModule';
import { User } from '../../types/users/users';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listVecinos } from '../vecinos/listVecinosModule';

export interface deleteVoluntarioState extends GenericState<unknown> {
  active: boolean;
  data: boolean;
  vecino: User | null;
}
const initialState: deleteVoluntarioState = {
  errors: null,
  loading: false,
  data: false,
  active: false,
  vecino: null,
};

const deleteVoluntarioModule = createApiModule({
  name: 'deleteVoluntario',
  apiMethod: deleteVoluntarioApi,
  initialState,
  onSuccess: (success) => [
    showSnackBar({
      severity: 'success',
      message: `Hemos dado de baja el voluntariado`,
      path: '/vecinos',
    }),
    listVecinos({}),
  ],
  onError: () => ({
    message: 'Se ha producido un error aplicando la baja de voluntario',
  }),
  reducers: {
    show(state, action: PayloadAction<User>) {
      state.active = true;
      state.vecino = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: deleteVoluntario,
  success: deleteVoluntarioOk,
  error: deleteVoluntarioKo,
  show,
  hide,
} = deleteVoluntarioModule.slice.actions;

export const { sagas } = deleteVoluntarioModule;

export default deleteVoluntarioModule;

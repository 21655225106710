import { Result, validatedApiCall } from '../api';
import { ExternalUser } from '../../types/users/users';
import { APIValidationError } from '../../types/api/api';
import { TipoSolicitud } from '../../types/solicitud/tipoSolicitud';
import { SubTipoSolicitud } from '../../types/solicitud/subTipoSolicitud';
import { Solicitud } from '../../types/solicitud/solicitud';
import { TargetSolicitud } from '../../types/solicitud/targetSolicitud.d';
import { UUID } from '../../types/standard';

export interface RegisterSolicitudDTO {
  tipo: TipoSolicitud;
  subtipo: SubTipoSolicitud;
  motivo: string;
  target: TargetSolicitud;
  beneficiario_id?: UUID | null;
  consentimiento_beneficiario: boolean;
  beneficiario_externo?: ExternalUser | null;
}

export const registerSolicitud = async (
  data: RegisterSolicitudDTO
): Promise<Result<Solicitud, APIValidationError>> => {
  return validatedApiCall<Solicitud>(`/api/solicitudes`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};

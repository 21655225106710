import React, { ReactElement } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import useIsRequired from '../../../../hooks/useIsRequired';
import InputField, { InputFieldProps } from '../InputField/InputField';
import styles from '../InputField/inputField.module.scss';
import { getError, Paths } from '../../../../helpers/inputs/inputHelper';
export interface ValidatedTextInput<T extends FieldValues>
  extends InputFieldProps {
  input: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    //name: keyof T;
    name: Paths<T, 2>;
  };
  errors: FieldErrors<T>;
  schema: any;
  label: string | ReactElement;
  description?: string;
}

const ValidatedInput = <T extends FieldValues>({
  errors,
  schema,
  label,
  input,
  description,
  ...other
}: ValidatedTextInput<T>): ReactElement => {
  const fname = input.name as string;
  const error = getError(errors, fname);
  const required = useIsRequired(fname, schema);

  return (
    <InputField
      input={input}
      error={error}
      description={description}
      // We change the label directly intead of passing the required prop
      label={
        label && required ? (
          <React.Fragment>
            {label}
            <span className={styles.asterisk}>{'\u00a0*'}</span>
          </React.Fragment>
        ) : (
          label
        )
      }
      {...other}
    />
  );
};

export default ValidatedInput;

import { APIValidationError } from '../../types/api/api';
import { Vecino } from '../../types/users/users';
import { Result, validatedApiCall } from '../api';

export type RegisterVecinoDTO = Vecino & {
  aceptar_pp: boolean;
};

export const registerVecino = async (
  data: RegisterVecinoDTO
): Promise<Result<Vecino, APIValidationError>> => {
  return validatedApiCall<Vecino>(`/api/vecinos`, {
    method: 'POST',
    auth: false,
    body: JSON.stringify(data),
  });
};

export type Permission =
  | 'create_user'
  | 'read_user'
  | 'update_user'
  | 'delete_user'
  | 'create_pueblo'
  | 'read_pueblo'
  | 'update_pueblo'
  | 'delete_pueblo'
  | 'create_entidad'
  | 'read_entidad'
  | 'update_entidad'
  | 'delete_entidad'
  | 'read_donacion'
  | 'delete_donacion'
  | 'read_donacion_own'
  | 'read_vecino'
  | 'delete_vecino'
  | 'update_vecino'
  | 'read_notification'
  | 'update_notification'
  | 'read_facturacion'
  | 'read_solicitud'
  | 'read_all_solicitud'
  | 'create_solicitud'
  | 'update_solicitud'
  | 'create_valoracion'
  | 'read_certificado'
  | 'read_voluntario'
  | 'create_voluntario'
  | 'create_comunicacion'
  | 'read_comunicacion'
  | 'create_actividad'
  | 'read_actividad';

export enum PermissionEnum {
  'create_user' = 'create_user',
  'read_user' = 'read_user',
  'update_user' = 'update_user',
  'delete_user' = 'delete_user',
  'create_pueblo' = 'create_pueblo',
  'read_pueblo' = 'read_pueblo',
  'update_pueblo' = 'update_pueblo',
  'delete_pueblo' = 'delete_pueblo',
  'create_entidad' = 'create_entidad',
  'read_entidad' = 'read_entidad',
  'update_entidad' = 'update_entidad',
  'delete_entidad' = 'delete_entidad',
  'read_donacion' = 'read_donacion',
  'delete_donacion' = 'delete_donacion',
  'read_donacion_own' = 'read_donacion_own',
  'read_vecino' = 'read_vecino',
  'delete_vecino' = 'delete_vecino',
  'update_vecino' = 'update_vecino',
  'read_notification' = 'read_notification',
  'update_notification' = 'update_notification',
  'read_facturacion' = 'read_facturacion',
  'read_solicitud' = 'read_solicitud',
  'read_all_solicitud' = 'read_all_solicitud',
  'create_solicitud' = 'create_solicitud',
  'update_solicitud' = 'update_solicitud',
  'create_valoracion' = 'create_valoracion',
  'read_certificado' = 'read_certificado',
  'read_voluntario' = 'read_voluntario',
  'create_voluntario' = 'create_voluntario',
  'create_comunicacion' = 'enums.auth.permission.create_comunicacion',
  'read_comunicacion' = 'enums.auth.permission.read_comunicacion',
  'create_actividad' = 'create_actividad',
  'read_actividad' = 'read_actividad',
}

import React from 'react';
import { connect } from 'react-redux';
import { selecthasUnreadAlerts } from '../../../../store/alerts/selectors';
import { isAreaGestion } from '../../../../store/app/selectors';
import {
  selectHasAnyPermission,
  selectHasSomeRole,
  selectIsOnlyVecino,
} from '../../../../store/auth/selectors';
import { RootState } from '../../../../store/store';
import styles from '../Layout/layout.module.scss';
import MenuUser from './MenuUser';
import MenuVecino from './MenuVecino';

interface MenuProps {
  isActive: boolean;
  isOpenMenu: boolean;
  userAvatar: string | null;
  canListUsers: boolean;
  canListPueblos: boolean;
  canListVecinos: boolean;
  canListEntidades: boolean;
  canViewNotifications: boolean;
  canListDonaciones: boolean;
  canViewFacturacion: boolean;
  canViewSolicitudes: boolean;
  canViewAcompanamiento: boolean;
  canReadDonacionOwn: boolean;
  canViewComunicaciones: boolean;
  canViewIndicadores: boolean;
  canPublishComunicaciones: boolean;
  canListActividades: boolean;
  hasUnreadAlerts: boolean;
  isVecino: boolean;
  isAdmin: boolean;
  isOnlyVecino: boolean;
  isRA: boolean;
  isRE: boolean;
  setIsOpenMenu: (open: boolean) => void;
  styleRoute: (route: string) => string;
}

const Menu: React.FC<MenuProps> = ({
  isActive,
  isOpenMenu,
  canListUsers,
  canListPueblos,
  canListEntidades,
  canViewNotifications,
  canViewSolicitudes,
  canViewAcompanamiento,
  canListDonaciones,
  canReadDonacionOwn,
  canViewComunicaciones,
  canPublishComunicaciones,
  canListActividades,
  canViewIndicadores,
  hasUnreadAlerts,
  isVecino,
  isAdmin,
  isOnlyVecino,
  isRA,
  isRE,
  setIsOpenMenu,
  canListVecinos,
  canViewFacturacion,
  styleRoute,
}) => {
  if (!isActive) return null;

  return (
    <nav
      data-testid={isOpenMenu ? 'isMenuVisible' : 'isMenuHidden'}
      className={`${styles.navigation} ${isOpenMenu && styles.isOpen}`}
    >
      <button
        aria-label={isOpenMenu ? 'Cerrar menú' : 'Abrir menú'}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
        className={`${styles.burger} ${styles.icon} ${
          isOpenMenu ? styles.isOpen : ''
        }`}
        type="button"
      >
        <span className={styles.burgerBox}>
          <span className={styles.burgerInner}></span>
        </span>
      </button>
      {!isOnlyVecino && !isRA && !isRE && (
        <MenuUser
          isOpenMenu={isOpenMenu}
          canListUsers={canListUsers}
          canListPueblos={canListPueblos}
          canListEntidades={canListEntidades}
          canViewSolicitudes={canViewSolicitudes}
          canListDonaciones={canListDonaciones}
          canViewComunicaciones={canViewComunicaciones}
          canListVecinos={canListVecinos}
          canViewFacturacion={canViewFacturacion}
          canListActividades={canListActividades}
          canViewIndicadores={canViewIndicadores}
          isAdmin={isAdmin}
          styleRoute={styleRoute}
        />
      )}
      <MenuVecino
        isActive={isActive}
        isOpenMenu={isOpenMenu}
        canViewNotifications={canViewNotifications}
        canViewAcompanamiento={canViewAcompanamiento}
        canReadDonacionOwn={canReadDonacionOwn}
        canPublishComunicaciones={canPublishComunicaciones}
        hasUnreadAlerts={hasUnreadAlerts}
        isVecino={isVecino}
        isAdmin={isAdmin}
        isOnlyVecino={isOnlyVecino || isRA || isRE}
        styleRoute={styleRoute}
      />
    </nav>
  );
};

export default connect((state: RootState) => ({
  isActive: isAreaGestion(state),
  canListUsers: selectHasSomeRole(state, ['admin', 'agente_soporte']),
  canListPueblos: selectHasAnyPermission(state, ['read_pueblo']),
  canListEntidades: selectHasAnyPermission(state, ['create_entidad']),
  canListDonaciones: selectHasAnyPermission(state, ['read_donacion']),
  canListVecinos: selectHasAnyPermission(state, ['read_vecino']),
  canReadDonacionOwn: selectHasAnyPermission(state, ['read_donacion_own']),
  canViewAcompanamiento: selectHasAnyPermission(state, [
    'read_solicitud',
    'create_solicitud',
  ]),
  canViewNotifications: selectHasAnyPermission(state, ['read_notification']),
  canViewFacturacion: selectHasAnyPermission(state, ['read_facturacion']),
  canViewSolicitudes: selectHasAnyPermission(state, ['read_all_solicitud']),
  canViewComunicaciones: selectHasAnyPermission(state, ['read_comunicacion']),
  canPublishComunicaciones: selectHasSomeRole(state, ['vecino']),
  canListActividades: selectHasAnyPermission(state, ['read_actividad']),
  canViewIndicadores: selectHasSomeRole(state, ['admin', 'agente_soporte']),
  hasUnreadAlerts: selecthasUnreadAlerts(state),
  isVecino: selectHasSomeRole(state, ['vecino']),
  isAdmin: selectHasSomeRole(state, ['admin']),
  isRE: selectHasSomeRole(state, ['responsable_entidad']),
  isRA: selectHasSomeRole(state, ['responsable_ayuntamiento']),
  isOnlyVecino: selectIsOnlyVecino(state),
}))(Menu);

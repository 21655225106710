import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const Dashboard = lazy(() => import('./dashboard'));

const FacturacionRoutes = [
  {
    path: 'facturacion',
    element: (
      <PrivateRoute
        allowedPermissions={['read_facturacion']}
        component={Dashboard}
      />
    ),
  },
];

export default FacturacionRoutes;

import {
  FacturacionDTO,
  getFacturacion,
} from '../../api/facturacion/getFacturacion';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';

export interface FacturacionModule extends GenericState<unknown> {
  data: FacturacionDTO;
}

const initialState: FacturacionModule = {
  errors: null,
  loading: false,
  data: { kpis: [], donaciones: [] } as FacturacionDTO,
};

const facturacionModule = createApiModule({
  name: 'listFacturacion',
  apiMethod: getFacturacion,
  initialState,
  parse: (facturacion) => facturacion,
  onSuccess: () => {},
  onError: () => ({
    message: 'Error listando la facturación',
  }),
  onValidationError: () => {},
});

export const {
  start: listFacturacion,
  success: listFacturacionOk,
  error: listFacturacionKo,
  reset: listFacturacionReset,
} = facturacionModule.slice.actions;

export default facturacionModule;

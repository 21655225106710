import { RootState } from '../../store';
/**                     SetupIntent                                  */
export const selectSetupIntentState = (state: RootState) => state.setupIntent;

export const selectSetupIntent = (state: RootState) =>
  selectSetupIntentState(state).setup_intent;

export const selectSetupIntentLoading = (state: RootState) =>
  selectSetupIntentState(state).loading;

export const selectSetupIntentError = (state: RootState) =>
  selectSetupIntentState(state).errors;

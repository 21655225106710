import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const Dashboard = lazy(() => import('./dashboard'));

const KpisRoutes = [
  {
    path: 'indicadores',
    element: (
      <PrivateRoute
        allowedRoles={['admin', 'agente_soporte']}
        component={Dashboard}
      />
    ),
  },
];

export default KpisRoutes;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreatePaymentMethodData, StripeError } from '@stripe/stripe-js';
import Stripe from 'stripe';
import { APIError } from '../../../types/api/api';

export interface PaymentMethodState {
  loading: boolean;
  payment_method: Stripe.PaymentMethod | null;
  errors: APIError | null;
}

const initialState: PaymentMethodState = {
  loading: false,
  payment_method: null,
  errors: null,
};

const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState: initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    createPaymentMethod(state, action: PayloadAction<CreatePaymentMethodData>) {
      state.loading = true;
      state.payment_method = null;
      state.errors = null;
    },
    createPaymentMethodOk(state, action: PayloadAction<Stripe.PaymentMethod>) {
      state.payment_method = action.payload;
      state.loading = false;
    },
    createPaymentMethodKo(state, action: PayloadAction<StripeError>) {
      state.loading = false;
      state.errors = {
        errorType: 'unprocessable',
        message: action.payload.message
          ? action.payload.message
          : 'Error inesperado',
      };
    },
    deletePaymentMethod(state, action: PayloadAction<Stripe.PaymentMethod>) {
      state.payment_method = null;
      state.loading = true;
      state.errors = null;
    },
    deletePaymentMethodOk(state) {
      state.loading = false;
    },
    deletePaymentMethodKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  reset,
  createPaymentMethod,
  createPaymentMethodKo,
  createPaymentMethodOk,
  deletePaymentMethod,
  deletePaymentMethodOk,
  deletePaymentMethodKo,
} = paymentMethodSlice.actions;

export default paymentMethodSlice;

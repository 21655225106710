import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const List = lazy(() => import('./List'));
const Register = lazy(() => import('./Register'));
const Update = lazy(() => import('./Update'));

const EntidadRoutes = [
  {
    path: 'entidades',
    element: (
      <PrivateRoute allowedPermissions={['read_entidad']} component={List} />
    ),
  },
  {
    path: 'entidad/nueva',
    element: (
      <PrivateRoute
        allowedPermissions={['create_entidad']}
        component={Register}
      />
    ),
  },
  {
    path: 'entidad/:id/editar',
    element: (
      <PrivateRoute
        allowedPermissions={['update_entidad']}
        component={Update}
      />
    ),
  },
];

export default EntidadRoutes;

import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';
import { APIValidationError } from '../../types/api/api';
import { Pueblo } from '../../types/pueblos/pueblo';

export const getPuebloById = async (
  id: UUID
): Promise<Result<Pueblo, APIValidationError>> => {
  return validatedApiCall<Pueblo>(`/api/pueblos/${id}`, {
    method: 'GET',
    auth: true,
  });
};

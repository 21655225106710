import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Stripe from 'stripe';
import { APIError } from '../../../types/api/api';

export interface CustomerState {
  loading: boolean;
  customer: Stripe.Customer | null;
  errors: APIError | null;
}

const initialState: CustomerState = {
  loading: false,
  customer: null,
  errors: null,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    loadCustomer(state) {
      state.loading = true;
      state.customer = null;
      state.errors = null;
    },
    loadCustomerOk(state, action: PayloadAction<Stripe.Customer>) {
      state.loading = false;
      state.customer = action.payload;
    },
    loadCustomerKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
    updateDefaultPaymentMethod(state, action: PayloadAction<string>) {
      state.loading = true;
      state.customer = null;
      state.errors = null;
    },
    updateDefaultPaymentMethodOk(
      state,
      action: PayloadAction<Stripe.Customer>
    ) {
      state.loading = false;
      state.customer = action.payload;
    },
    updateDefaultPaymentMethodKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  reset,
  loadCustomer,
  loadCustomerOk,
  loadCustomerKo,
  updateDefaultPaymentMethod,
  updateDefaultPaymentMethodOk,
  updateDefaultPaymentMethodKo,
} = customerSlice.actions;

export default customerSlice;

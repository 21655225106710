import { Severity } from '../../components/common/SnackBar/SnackBar';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SnackBarState = {
  isOpen: boolean;
  message: string | JSX.Element;
  path?: string;
  severity?: Severity;
  autoHideDuration?: number;
  id: number;
};

export const initialState: SnackBarState = {
  isOpen: false,
  message: '',
  path: '',
  severity: 'neutral',
  autoHideDuration: NaN,
  id: 0,
};

export type SnackBarSettings = Omit<SnackBarState, 'id' | 'isOpen'>;

const snackBarSlice = createSlice({
  name: 'snackBar',
  initialState,
  reducers: {
    showSnackBar(state, action: PayloadAction<SnackBarSettings>) {
      state.message = action.payload.message;
      state.path = action.payload.path;
      state.severity = action.payload.severity;
      state.isOpen = true;
      state.autoHideDuration = action.payload.autoHideDuration;
      state.id = state.id + 1;
    },
    hideSnackBar(state) {
      state.isOpen = false;
    },
  },
});

export const { showSnackBar, hideSnackBar } = snackBarSlice.actions;

export default snackBarSlice;

import { APIValidationError } from '../../types/api/api';
import { Result, validatedApiCall } from '../api';

export const getBajaRestrictions = async (): Promise<
  Result<string[], APIValidationError>
> => {
  return validatedApiCall<string[]>('/api/vecinos/delete_restrictions', {
    method: 'GET',
    auth: true,
  });
};

import { updateActividad } from '../../api/actividad/updateActividad';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { resetActividad } from './actividadModule';

const updateActividadModule = createApiModule({
  name: 'updateActividad',
  apiMethod: updateActividad,
  onSuccess: () => [
    resetActividad(),
    showSnackBar({
      severity: 'success',
      message: `La actividad se ha modificado correctamente.`,
      path: '/actividades',
    }),
  ],

  onError: () => ({
    message: 'Error modificando la activiad',
  }),
  onValidationError: (error) => ({
    message: error.message || 'Error modificando la actividad',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateActividadModule.slice.actions;

export const { sagas } = updateActividadModule;

export default updateActividadModule;

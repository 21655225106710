import { getSocio } from '../../api/vecinos/vecinoSocio';
import { VecinoSocio } from '../../types/socio/socio';
import createApiModule from '../createApiModule';

const getVecinoSocioModule = createApiModule({
  name: 'vecinoSocio',
  initialData: {} as VecinoSocio,
  apiMethod: getSocio,
  parse: (data) => data,
  onError: () => ({
    message: 'Error recuperando sus datos de socio/a',
  }),
  onSuccess: () => {},
});

export const {
  start: getVecinoSocio,
  success: getVecinoSocioOk,
  error: getVecinoSocioErrors,
  refresh: getVecinoSocioRefresh,
} = getVecinoSocioModule.slice.actions;

export default getVecinoSocioModule;

// Esto es un hack terrible para gestionar el orden en que se
// importan los componentes cuando webpack genera bundles.
//
// Sin este hack cuando webpack extrae los CSS resulta que no
// consigue garantizar en qué orden van a terminar incluyéndose
// en la página, así que minicss-extract-plugin lanza warnings
// al respecto y el build falla por eso.
//
// See: https://github.com/facebook/create-react-app/issues/5372

import React from 'react';
import PopupMenu from './components/common/PopupMenu/PopupMenu';

const ImportOrderEnforcer = () => () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _popup = () => <PopupMenu children={[]} />;
};

export default ImportOrderEnforcer;

import { getBajaRestrictions } from '../../../api/users/getBajaRestrictions';
import createApiModule from '../../createApiModule';

const bajaVecinoRestrictionsModule = createApiModule({
  name: 'bajaVecinoRestrictions',
  initialData: [] as string[],
  apiMethod: getBajaRestrictions,
  parse: (data) => data,
  onError: () => ({
    message: 'Hay un problema comprobando si se puede dar de baja',
  }),
  onSuccess: () => {},
});

export const {
  start: getVecinoBajaRestricions,
  success: getVecinoBajaRestricionsOk,
  error: getVecinoBajaRestricionsKo,
  refresh: getVecinoBajaRestricionsRefresh,
} = bajaVecinoRestrictionsModule.slice.actions;

export default bajaVecinoRestrictionsModule;

import { PayloadAction } from '@reduxjs/toolkit';
import { updatePueblo as callUpdatePueblo } from '../../api/pueblos/updatePueblo';
import { GenericState } from '../../types/apiModule';
import { Pueblo } from '../../types/pueblos/pueblo';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listPueblosRefresh } from './listPuebloModule';

export interface updatePuebloState extends GenericState<unknown> {
  active: boolean;
  pueblo: Pueblo;
}
const initialState: updatePuebloState = {
  errors: null,
  data: [],
  loading: false,
  active: false,
  pueblo: {} as Pueblo,
};

const updatePuebloModule = createApiModule({
  name: 'updatePueblo',
  initialState,
  apiMethod: callUpdatePueblo,
  onSuccess: (pueblo) => [
    showSnackBar({
      severity: 'success',
      message: `Se ha cambialo la entidad principal de ${pueblo.poblacion} correctamente.`,
    }),
    listPueblosRefresh(),
  ],
  onError: () => ({
    message: 'Error modificando el municipio',
  }),
  onValidationError: () => ({
    message: 'Error cambiando la entidad principal del municipio',
  }),
  reducers: {
    show(state, action: PayloadAction<Pueblo>) {
      state.active = true;
      state.pueblo = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: updatePueblo,
  success: updatePuebloOk,
  error: updatePuebloKo,
  hide,
  show,
} = updatePuebloModule.slice.actions;

export const { sagas } = updatePuebloModule;

export default updatePuebloModule;

/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { PUBLIC_KEY } from '../config/stripe';

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(PUBLIC_KEY, { locale: 'es-ES' });
  }
  return stripePromise;
};

export default getStripe;

import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { PAGE, SIZE } from '../../types/standard';
import { Comunicacion } from '../../types/comunicacion/comunicacion';

export interface GetComunicacionesDTO {
  page?: PAGE;
  size?: SIZE;
}

export interface ComunicacionDTO {
  data: Comunicacion[];
  links: LinksPage;
  meta: PageInfo;
}

export const getComunicaciones = async (
  data: GetComunicacionesDTO
): Promise<Result<ComunicacionDTO, APIValidationError>> => {
  return validatedApiCall<ComunicacionDTO>(
    `/api/comunicaciones?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};

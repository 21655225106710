import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const List = lazy(() => import('./List'));
const Register = lazy(() => import('./Register'));
const Update = lazy(() => import('./Update'));

const UserRoutes = [
  {
    path: 'usuarios',
    element: (
      <PrivateRoute
        allowedRoles={['admin', 'agente_soporte']}
        component={List}
      />
    ),
  },
  {
    path: 'usuario/nuevo',
    element: (
      <PrivateRoute
        allowedRoles={['admin', 'agente_soporte']}
        component={Register}
      />
    ),
  },
  {
    path: 'usuario/:id/editar',
    element: (
      <PrivateRoute
        allowedRoles={['admin', 'agente_soporte']}
        component={Update}
      />
    ),
  },
];

export default UserRoutes;

import {
  useForm,
  FieldValues,
  UseFormOptions,
  UseFormMethods,
} from 'react-hook-form';

const useOurForm: typeof useForm = <
  FormValues extends FieldValues,
  ValidationContext extends object = object
>(
  opts?: UseFormOptions<FormValues, ValidationContext>
): UseFormMethods<FormValues> => {
  let newOpts = opts;
  if (process.env.REACT_APP_CLIENTSIDE_VALIDATION_DISABLED && opts?.resolver) {
    const { resolver, ...wat } = opts;
    newOpts = wat;
  }
  return useForm(newOpts);
};

export default useOurForm;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ReactComponent as IconDashboard } from '../../../../assets/asideMenu/icon_dashboard.svg';
import { ReactComponent as IconDonaciones } from '../../../../assets/asideMenu/icon_donaciones.svg';
import { ReactComponent as IconEntidad } from '../../../../assets/asideMenu/icon_entidades.svg';
import { ReactComponent as IconFacturacion } from '../../../../assets/asideMenu/icon_facturacion.svg';
import { ReactComponent as IconPueblos } from '../../../../assets/asideMenu/icon_pueblos.svg';
import { ReactComponent as IconUsers } from '../../../../assets/asideMenu/icon_users.svg';
import { ReactComponent as IconVecino } from '../../../../assets/asideMenu/icon_vecinos.svg';
import styles from '../Layout/layout.module.scss';

interface MenuUserProps {
  isOpenMenu: boolean;
  canListUsers: boolean;
  canListPueblos: boolean;
  canListVecinos: boolean;
  canListEntidades: boolean;
  canListDonaciones: boolean;
  canViewFacturacion: boolean;
  canViewSolicitudes: boolean;
  canViewComunicaciones: boolean;
  canListActividades: boolean;
  canViewIndicadores: boolean;
  isAdmin: boolean;
  styleRoute: (route: string) => string;
}

const MenuUser: React.FC<MenuUserProps> = ({
  isOpenMenu,
  canListUsers,
  canListPueblos,
  canListEntidades,
  canViewSolicitudes,
  canListDonaciones,
  canViewComunicaciones,
  canListVecinos,
  canViewFacturacion,
  canListActividades,
  canViewIndicadores,
  isAdmin,
  styleRoute,
}) => {
  return (
    <ul className={`${styles.mainMenu} ${isAdmin ? '' : styles.isInternal}`}>
      <li className={styleRoute('/')}>
        <Link
          data-testid="dashboardLink"
          title="Inicio"
          className={styles.menuLink}
          to="/"
        >
          <div className={styles.iconWrapper}>
            <IconDashboard className={styles.menuIcon} />
          </div>
          <span
            data-testid={
              isOpenMenu ? 'isDashboardVisible' : 'isDashboardHidden'
            }
            className={styles.menuText}
          >
            Inicio
          </span>
        </Link>
      </li>
      {canListUsers && (
        <li className={`${styleRoute('/usuarios')}`}>
          <Link
            data-testid="usersLink"
            className={styles.menuLink}
            to="/usuarios"
            title="Usuarios/as"
          >
            <div className={styles.iconWrapper}>
              <IconUsers className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isUsersVisible' : 'isUsersHidden'}
              className={styles.menuText}
            >
              Usuarios/as
            </span>
          </Link>
        </li>
      )}
      {canListVecinos && (
        <li className={`${styleRoute('/vecinos')}`}>
          <Link
            data-testid="vecinosLink"
            className={styles.menuLink}
            to="/vecinos"
            title="Vecinos"
          >
            <div className={styles.iconWrapper}>
              <IconVecino className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isVecinosVisible' : 'isVecinosHidden'}
              className={styles.menuText}
            >
              Vecinos/as
            </span>
          </Link>
        </li>
      )}
      {canListPueblos && (
        <li className={styleRoute('/pueblos')}>
          <Link
            data-testid="pueblosLink"
            className={styles.menuLink}
            to="/pueblos"
            title="Municipios"
          >
            <div className={styles.iconWrapper}>
              <IconPueblos className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isPuebloVisible' : 'isPuebloHidden'}
              className={styles.menuText}
            >
              Municipios
            </span>
          </Link>
        </li>
      )}
      {canListEntidades && (
        <li className={styleRoute('/entidades')}>
          <Link
            data-testid="entidadesLink"
            className={styles.menuLink}
            to="/entidades"
            title="Entidades"
          >
            <div className={styles.iconWrapper}>
              <IconEntidad className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isEntidadVisible' : 'isEntidadHidden'}
              className={styles.menuText}
            >
              Entidades
            </span>
          </Link>
        </li>
      )}

      {canListDonaciones && (
        <li className={styleRoute('/donaciones')}>
          <Link
            data-testid="usersLink"
            className={styles.menuLink}
            to="/donaciones"
            title="Donaciones"
          >
            <div className={styles.iconWrapper}>
              <IconDonaciones className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isUsersVisible' : 'isUsersHidden'}
              className={styles.menuText}
            >
              Donaciones
            </span>
          </Link>
        </li>
      )}

      {canViewFacturacion && (
        <li className={styleRoute('/facturacion')}>
          <Link
            data-testid="facturacionLink"
            className={styles.menuLink}
            to="/facturacion"
            title="Facturación"
          >
            <div className={styles.iconWrapper}>
              <IconFacturacion className={styles.menuIcon} />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isFacturacionVisible' : 'isFacturacionHidden'
              }
              className={styles.menuText}
            >
              Facturación
            </span>
          </Link>
        </li>
      )}
      {canViewSolicitudes && (
        <li className={styleRoute('/solicitudes')}>
          <Link
            data-testid="solicitudesLink"
            className={styles.menuLink}
            to="/solicitudes"
            title="Solicitudes"
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'mail-bulk']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isFacturacionVisible' : 'isFacturacionHidden'
              }
              className={styles.menuText}
            >
              Solicitudes
            </span>
          </Link>
        </li>
      )}
      {canViewComunicaciones && (
        <li className={styleRoute('/comunicaciones')}>
          <Link
            data-testid="comunicacionesLink"
            className={styles.menuLink}
            to="/comunicaciones"
            title="Comunicaciones"
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'paper-plane']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu
                  ? 'isComunicacionesVisible'
                  : 'isComunicacionesHidden'
              }
              className={styles.menuText}
            >
              Comunicaciones
            </span>
          </Link>
        </li>
      )}
      {canListActividades && (
        <li className={styleRoute('/actividades')}>
          <Link
            data-testid="actividadesLink"
            className={styles.menuLink}
            to="/actividades"
            title="Actividades"
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'project-diagram']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isActividadesVisible' : 'isActividadesHidden'
              }
              className={styles.menuText}
            >
              Actividades
            </span>
          </Link>
        </li>
      )}
      {canViewIndicadores && (
        <li className={styleRoute('/indicadores')}>
          <Link
            data-testid="actividadesLink"
            className={styles.menuLink}
            to="/indicadores"
            title="Indicadores"
          >
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon
                icon={['fas', 'chart-pie']}
                size="lg"
                className={styles.menuIcon}
              />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isIndicadoresVisible' : 'isIndicadoresHidden'
              }
              className={styles.menuText}
            >
              Indicadores
            </span>
          </Link>
        </li>
      )}
    </ul>
  );
};
export default MenuUser;

import { bajaVecino as callBajaVecino } from '../../../api/users/bajaVecino';
import { logout } from '../../auth/authSlice';
import createApiModule from '../../createApiModule';
import { showSnackBar } from '../../snackBar/snackBarSlice';

const bajaVecinoModule = createApiModule({
  name: 'bajaVecino',
  apiMethod: callBajaVecino,
  onError: () => ({
    message: 'Se ha producido un error procesando su baja',
  }),
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'Ha sido eliminado de la plataforma',
    }),
    logout(),
  ],
});

export const {
  start: bajaVecino,
  success: bajaVecinoOk,
  error: bajaVecinoKo,
} = bajaVecinoModule.slice.actions;

export default bajaVecinoModule;

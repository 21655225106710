import React from 'react';
import styles from './button.module.scss';

export type ButtonVariant =
  | 'positive'
  | 'negative'
  | 'icon'
  | 'reset'
  | 'default'
  | 'download';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: ButtonVariant;
}

const colorPicker = (variant: ButtonVariant): any => {
  switch (variant) {
    case 'positive':
      return styles.buttonPositive;
    case 'negative':
      return styles.buttonNegative;
    case 'icon':
      return styles.icon;
    case 'reset':
      return styles.isReset;
    case 'download':
      return styles.isDownload;
    case 'default':
      return '';
  }
};

const Button: React.FC<ButtonProps> = ({
  variant = 'default',
  children,
  ...props
}) => (
  <button
    className={
      variant !== 'default'
        ? `${variant !== 'icon' ? styles.button : ''} ${colorPicker(variant)}`
        : ''
    }
    {...props}
  >
    {children}
  </button>
);

export default Button;

import { changeEstadoSolicitud } from '../../api/solicitudes/changeEstadoSolicitud';
import { GenericState } from '../../types/apiModule';
import { Solicitud } from '../../types/solicitud/solicitud';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { resetSolicitud } from './solicitudModule';

export interface ChangeEstadoSolicitudModule extends GenericState<unknown> {
  data: Solicitud;
}

const initialState: ChangeEstadoSolicitudModule = {
  errors: null,
  loading: false,
  data: {} as Solicitud,
};

const changeEstadoSolicitudModule = createApiModule({
  name: 'changeEstadoSolicitud',
  apiMethod: changeEstadoSolicitud,
  initialState,
  onSuccess: (solicitud) => [
    showSnackBar({
      severity: 'success',
      message: 'La solicitud se ha cambiado el estado correctamente.',
      path: '/solicitudes',
    }),
    resetSolicitud(),
  ],
  onError: () => ({
    message: 'Error cambiando el estado de la solicitud',
  }),
  onValidationError: () => ({
    message: 'Error cambiando el estado de la solicitud',
  }),
});

export const {
  start: changeEstado,
  success: changeEstadoOk,
  error: changeEstadoKo,
} = changeEstadoSolicitudModule.slice.actions;

export default changeEstadoSolicitudModule;

import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';
import { AlertsDTO } from './getAlerts';

export const markAlertsAsRead = async (
  data: UUID[]
): Promise<Result<AlertsDTO, APIValidationError>> =>
  validatedApiCall<AlertsDTO>(`/api/alertas/mark_as_read`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ alerta_ids: data }),
  });

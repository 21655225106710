import {
  Action,
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createSlice,
  Draft,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { Result } from '../api/api';
import { APIError, APIValidationError, Await } from '../types/api/api';
import { GenericState as BaseModuleState } from '../types/apiModule';
import snackBarSlice, { SnackBarSettings } from './snackBar/snackBarSlice';

function isAction(subject: any): subject is Action {
  return (
    typeof subject !== 'undefined' &&
    subject !== null &&
    typeof subject === 'object' &&
    typeof subject.type !== 'undefined'
  );
}

function isActionArray(subject: any): subject is Action[] {
  return (
    typeof subject !== 'undefined' &&
    subject !== null &&
    typeof subject === 'object' &&
    Array.isArray(subject) &&
    subject.every((s) => isAction(s))
  );
}

function isMessage(
  subject: any
): subject is Pick<SnackBarSettings, 'message' | 'path'> {
  return (
    typeof subject !== 'undefined' &&
    subject !== null &&
    typeof subject === 'object' &&
    typeof subject.message === 'string'
  );
}

function toActionArray(report: any, severity: SnackBarSettings['severity']) {
  if (isAction(report)) {
    return [report];
  } else if (isActionArray(report)) {
    return report;
  } else if (isMessage(report)) {
    return [
      snackBarSlice.actions.showSnackBar({
        ...report,
        severity,
      }),
    ];
  }
  return [];
}

type ReportEvent =
  | void
  | Action
  | Action[]
  | Pick<SnackBarSettings, 'message' | 'path'>;

type APIModuleDefinition<
  DataType,
  ModuleState extends BaseModuleState<DataType>,
  RequestDTO,
  ResponseDTO,
  Reducers extends SliceCaseReducers<ModuleState>
> = {
  name: string;
  apiMethod: (
    payload: RequestDTO
  ) => Promise<Result<ResponseDTO, APIValidationError>>;
  debounceDelay?: number;
  reducers?: ValidateSliceCaseReducers<ModuleState, Reducers>;
  parse?: (response: ResponseDTO, currentState: DataType) => DataType;
  onSuccess: (data: ResponseDTO) => ReportEvent;
  afterSuccess?: (data: ResponseDTO) => ReportEvent;
  onError: (error: APIError) => ReportEvent;
  onValidationError?: (error: APIValidationError) => ReportEvent;
} & (
  | {
      initialState: ModuleState;
    }
  | {
      initialData: DataType;
    }
  | {}
);

const createApiModule = <
  DataType,
  ModuleState extends BaseModuleState<DataType>,
  RequestDTO,
  ResponseDTO,
  Reducers extends SliceCaseReducers<ModuleState>
>({
  name,
  apiMethod,
  debounceDelay = 0,
  parse,
  reducers = {} as ValidateSliceCaseReducers<ModuleState, Reducers>,
  afterSuccess,
  onSuccess,
  onError,
  onValidationError = onError,
  ...rest
}: APIModuleDefinition<
  DataType,
  ModuleState,
  RequestDTO,
  ResponseDTO,
  Reducers
>) => {
  let lastRequest: RequestDTO;

  const initialData =
    'initialState' in rest
      ? rest.initialState.data
      : 'initialData' in rest
      ? rest.initialData
      : null;

  const actualInitialState =
    'initialState' in rest
      ? rest.initialState
      : ({
          loading: false,
          errors: null,
          data: initialData,
        } as ModuleState);

  const slice = createSlice({
    name,
    initialState: actualInitialState,
    reducers: {
      start(state, _action: PayloadAction<RequestDTO>) {
        state.loading = true;
        state.errors = null;
        lastRequest = _action.payload;
      },
      success(state, action: PayloadAction<ResponseDTO>) {
        state.loading = false;
        state.errors = null;
        if (parse) {
          state.data = parse(
            action.payload,
            state.data as DataType
          ) as Draft<DataType>;
        }
      },
      error(state, action: PayloadAction<APIError>) {
        state.loading = false;
        state.errors = action.payload;
        state.data = initialData as Draft<DataType>;
      },
      reset() {
        return { ...actualInitialState };
      },
      refresh() {
        return { ...actualInitialState };
      },
      ...reducers,
    },
  });

  const startSaga = function* (
    action: PayloadAction<RequestDTO>
  ): Generator<any, void, any> {
    if (debounceDelay) {
      yield delay(debounceDelay);
    }

    try {
      const result = (yield call(apiMethod, action.payload)) as Await<
        ReturnType<typeof apiMethod>
      >;
      switch (result.type) {
        case 'ok': {
          const actions = toActionArray(onSuccess(result.value), 'success');
          for (const action of actions) {
            yield put(action);
          }

          yield put(
            (
              slice.actions.success as ActionCreatorWithPayload<
                ResponseDTO,
                string
              >
            )(result.value)
          );
          if (afterSuccess) {
            const actionsAfterSucccess = toActionArray(
              afterSuccess(result.value),
              'success'
            );
            for (const action of actionsAfterSucccess) {
              yield put(action);
            }
          }
          return;
        }

        case 'validation-error': {
          const actions = toActionArray(
            onValidationError(result.value),
            'error'
          );
          for (const action of actions) {
            yield put(action);
          }
          yield put(
            (slice.actions.error as ActionCreatorWithPayload<APIError, string>)(
              result.value
            )
          );
          return;
        }
      }
    } catch (e) {
      const error: APIError = e as APIError;
      const actions = toActionArray(onError(error), 'error');
      for (const action of actions) {
        yield put(action);
      }
      yield put(
        (slice.actions.error as ActionCreatorWithPayload<APIError, string>)(
          error
        )
      );
      return;
    }
  };

  const refreshSaga = function* (): Generator<any, void, any> {
    if (!lastRequest) {
      return;
    }

    if (debounceDelay) {
      yield delay(debounceDelay);
    }
    yield put(
      (slice.actions.start as ActionCreatorWithPayload<RequestDTO>)(lastRequest)
    );
  };

  return {
    slice,
    saga: startSaga,
    sagas: [
      takeLatest<PayloadAction<RequestDTO>>(
        (slice.actions.start as ActionCreatorWithPayload<ResponseDTO, string>)
          .type,
        startSaga
      ),
      takeLatest(
        (slice.actions.refresh as ActionCreatorWithoutPayload).type,
        refreshSaga
      ),
    ],
  };
};

export default createApiModule;

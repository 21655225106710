import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import registerIcons from './helpers/registerIcons';
import ImportOrderEnforcer from './ImportOrderEnforcer';
import Index from './routes';
import store, { history, persistor } from './store/store';
import { ReduxRouter } from '@lagunovsky/redux-react-router';

// See the module to understand what this is
ImportOrderEnforcer();
registerIcons();

const App: React.FC<{}> = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ReduxRouter history={history}>
        <Index />
      </ReduxRouter>
    </PersistGate>
  </Provider>
);

export default App;

import { AlertsDTO, getAlerts } from '../../api/alerts/getAlerts';
import { Alert } from '../../types/alerts/alerts';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import createApiModule from '../createApiModule';

interface NewAlertsModule extends GenericState<unknown> {
  data: AlertsDTO;
}

const initialState: NewAlertsModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Alert[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const newAlertsModule = createApiModule({
  name: 'newAlerts',
  apiMethod: getAlerts,
  initialState,
  parse: (response) => response,
  onSuccess: () => {},
  onError: () => {},
  onValidationError: () => {},
});

export const {
  start: listNewAlerts,
  success: listNewAlertsOk,
  error: listNewAlertsKo,
  reset: resetAlerts,
} = newAlertsModule.slice.actions;

export const { sagas } = newAlertsModule;

export default newAlertsModule;

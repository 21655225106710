import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const List = lazy(() => import('./List'));
const Register = lazy(() => import('./Register'));

const ComunicacionRoutes = [
  {
    path: 'comunicaciones',
    element: (
      <PrivateRoute
        allowedPermissions={['read_comunicacion']}
        component={List}
      />
    ),
  },
  {
    path: 'comunicacion/nueva',
    element: (
      <PrivateRoute
        allowedPermissions={['create_comunicacion']}
        component={Register}
      />
    ),
  },
];

export default ComunicacionRoutes;

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectIsLoggedIn } from '../../../../store/auth/selectors';
import { RootState } from '../../../../store/store';

interface PublicRouteProps {
  component: React.FC;
  allowAuthenticated?: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  component: Component,
  allowAuthenticated = false,
}) => {
  const location = useLocation();
  const { isAuthenticated } = useSelector((state: RootState) => ({
    isAuthenticated: selectIsLoggedIn(state),
  }));

  return !isAuthenticated || allowAuthenticated ? (
    <Component />
  ) : (
    <Navigate to={'/'} state={{ from: location }} replace />
  );
};

export default PublicRoute;

import { setPrincipalEntidad } from '../../api/entidades/setPrincipalEntidad';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listEntidadesRefresh } from './listEntidadModule';

const setPrincipalEntidadModule = createApiModule({
  name: 'setPrincipalEntidad',
  apiMethod: setPrincipalEntidad,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: `Ahora es entidad principal del municipio.`,
    }),
    listEntidadesRefresh(),
  ],
  onError: () => ({
    message: 'Error asignando entidad principal',
  }),
  onValidationError: (error) => ({
    message: error.errors ? error.errors[0][0] : error.message,
  }),
});

export const {
  start: assignPrincipal,
  success: assignPrincipalOk,
  error: assignPrincipalKo,
} = setPrincipalEntidadModule.slice.actions;

export default setPrincipalEntidadModule;

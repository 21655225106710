import { APIError } from '../../types/api/api';
import { Permission } from '../../types/auth/permissions';
import { Role } from '../../types/auth/roles';
import { RootState } from '../store';

export const selectAuthSlice = (state: RootState) => state.auth;

export const selectAuthUser = (state: RootState) =>
  selectAuthSlice(state)?.user || null;

export const selectAuthData = (state: RootState) =>
  selectAuthUser(state)?.data || null;

export const selectUserIsCuidador = (state: RootState) =>
  selectAuthData(state)?.user_states?.includes('cuidador') || false;
export const selectAuthAvatar = (state: RootState) =>
  selectAuthUser(state)?.data.imagen_perfil || null;

export const selectIsLoggedIn = (state: RootState) => !!selectAuthUser(state);

export const selectUserRoles = (state: RootState) =>
  selectAuthData(state)?.roles;

export const selectUserPermissions = (state: RootState): Permission[] =>
  selectAuthUser(state)?.permissions || [];

export const selectHasSomeRole = (state: RootState, roles: Role[]) => {
  const userRoles = selectUserRoles(state);
  if (!userRoles) {
    return false;
  }
  return roles.find((role) => role in userRoles) ? true : false;
};

export const selectHasRole = (state: RootState, role: Role) => {
  const userRoles = selectUserRoles(state);
  return userRoles ? role in userRoles : false;
};

export const selectIsOnlyVecino = (state: RootState) => {
  const userRoles = selectUserRoles(state);
  return userRoles
    ? 'vecino' in userRoles && Object.keys(userRoles).length === 1
    : false;
};

export const selectHasAnyPermission = (
  state: RootState,
  permissions: Permission[]
) => {
  const userPerms = selectUserPermissions(state);
  return permissions.some((p) => userPerms.includes(p));
};

export const selectCanReadAlerts = (state: RootState) => {
  return selectHasAnyPermission(state, ['read_notification']);
};

export const selectAuthLoading = (state: RootState) =>
  selectAuthSlice(state)?.loading;

export const selectAuthErrors = (state: RootState) =>
  selectAuthSlice(state)?.errors;

export const selectServerErrors = (state: RootState): APIError | null =>
  selectAuthSlice(state)?.errors;

export const getOwnName = (state: RootState, id: string) => {
  if (selectAuthData(state)?.id === id) {
    const user = selectAuthData(state);
    return user ? `${user.nombre} ${user.apellido1} ${user.apellido2}` : null;
  } else {
    return null;
  }
};
export const selectAuthId = (state: RootState) =>
  selectAuthSlice(state)?.user?.data.id;

import { RootState } from '../store';

/******************** VOLUNTARIOS **************************************/
export const selectVoluntariosSlice = (state: RootState) => state.voluntarios;
export const selectVoluntarios = (state: RootState) =>
  selectVoluntariosSlice(state).data;
export const selectVoluntariosLoading = (state: RootState) =>
  selectVoluntariosSlice(state).loading;

/**                      VoluntarioEstado                            */
export const selectVoluntarioEstado = (state: RootState) =>
  state.voluntarioEstado.data;
export const selectVoluntarioEstadoLoading = (state: RootState) =>
  state.voluntarioEstado.loading;

/**                      VoluntarioEstado                            */
export const selectBajaVoluntarioLoading = (state: RootState) =>
  state.voluntarioBaja.loading;
/******************** BAJA VOLUNTARIOS **************************************/
export const selectDeleteVoluntarioSlice = (state: RootState) =>
  state.voluntarioDelete;
export const selectDeleteVoluntarioVecino = (state: RootState) =>
  selectDeleteVoluntarioSlice(state).vecino;
export const selectDeleteVoluntarioActive = (state: RootState) =>
  selectDeleteVoluntarioSlice(state).active;
export const selectDeleteVoluntarioErrors = (state: RootState) =>
  selectVoluntariosSlice(state).errors;

/******************** SHOW VOLUNTARIOS **************************************/
export const selectShowVoluntarioSlice = (state: RootState) => state.voluntario;
export const selectShowVoluntario = (state: RootState) =>
  selectShowVoluntarioSlice(state).data;
export const selectShowVoluntarioLoading = (state: RootState) =>
  selectShowVoluntarioSlice(state).loading;
export const selectShowVoluntarioError = (state: RootState) =>
  selectShowVoluntarioSlice(state).errors;

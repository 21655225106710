import { changePassword } from '../../api/forgotPassword/changePassword';
import createApiModule from '../createApiModule';

const changePasswordModule = createApiModule({
  name: 'updatePassword',
  apiMethod: changePassword,
  onSuccess: () => ({
    message: 'Se ha modificado la contraseña.',
    path: '/login',
  }),
  onError: () => ({
    message: 'El/La usuario/a no existe.',
  }),
  onValidationError: () => ({
    message: 'El/La usuario/a no existe.',
  }),
});

export const {
  start: updatePassword,
  success: updatePasswordOk,
  error: updatePasswordKo,
} = changePasswordModule.slice.actions;

export default changePasswordModule;

import { registerExternalDonator } from '../../api/users/registerExternalDonator';
import createApiModule from '../createApiModule';

const externalDonatorModule = createApiModule({
  name: 'externalDonator',
  apiMethod: registerExternalDonator,
  initialData: null,
  parse: (donator) => donator,
  onSuccess: () => {},
  onError: () => {},
  onValidationError: () => {},
});

export const {
  start: storeDonator,
  success: storeDonatorOk,
  error: storeDonatorKo,
} = externalDonatorModule.slice.actions;

export default externalDonatorModule;

import { lazy } from 'react';
import PublicRoute from '../../components/common/Router/PublicRoute/PublicRoute';

const Valorar = lazy(() => import('./Valorar'));
const ValorarSuccess = lazy(() => import('./ValorarSuccess'));

const ValoracionesRoutes = [
  {
    path: 'valorar',
    children: [
      {
        index: true,
        element: <PublicRoute component={Valorar} allowAuthenticated={true} />,
      },
      {
        path: ':valoracion_id',
        element: <PublicRoute component={Valorar} allowAuthenticated={true} />,
      },
      {
        path: 'success',
        element: (
          <PublicRoute component={ValorarSuccess} allowAuthenticated={true} />
        ),
      },
    ],
  },
];

export default ValoracionesRoutes;

import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';
import { allPermissions } from '../../components/LoginRestrictions/LoginRestrictions';

const Home = lazy(() => import('./Home'));

const HomeRoutes = [
  {
    index: true,
    element: (
      <PrivateRoute allowedPermissions={allPermissions} component={Home} />
    ),
  },
];

export default HomeRoutes;

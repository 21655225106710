import { registerComunicacion } from '../../api/comunicacion/registerComunicacion';
import createApiModule from '../createApiModule';

const registerComunicacionModule = createApiModule({
  name: 'registerComunicacion',
  apiMethod: registerComunicacion,
  onSuccess: (solicitud) => ({
    message: 'La solicitud se ha enviado correctamente.',
    path: '/',
  }),
  onError: () => ({
    message: 'Error enviando la solicitud',
  }),
  onValidationError: () => ({
    message: 'Error enviando la solicitud',
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerComunicacionModule.slice.actions;

export default registerComunicacionModule;

import { PayloadAction } from '@reduxjs/toolkit';
import { deleteDonacion as callDeleteDonacion } from '../../api/donaciones/deleteDonaciones';
import { GenericState } from '../../types/apiModule';
import { Donacion } from '../../types/donaciones/donaciones';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listOwnDonacionesRefresh } from '../donacionOwn/listDonacionesOwnModule';
export interface deleteDonacionesState extends GenericState<unknown> {
  active: boolean;
  data: Donacion[];
  donacion: Donacion | null;
}
const initialState: deleteDonacionesState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  donacion: null,
};

const deleteDonacionModule = createApiModule({
  name: 'deleteDonacion',
  initialState,
  apiMethod: callDeleteDonacion,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'Se ha anulado la donación',
    }),
    listOwnDonacionesRefresh(),
  ],
  onError: () => ({
    message: 'Se ha producido un error anulando la donación',
  }),
  reducers: {
    show(state, action: PayloadAction<Donacion>) {
      state.active = true;
      state.donacion = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: deleteDonacion,
  success: deleteDonacionOk,
  error: deleteDonacionKo,
  hide: hideDeleteDonacion,
  show: showDeleteDonacion,
} = deleteDonacionModule.slice.actions;

export default deleteDonacionModule;

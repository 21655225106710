import { exportUsers } from '../../api/users/exportUser';
import { downloadFileFromContent } from '../../helpers/redirects/downloadFileFromContent';
import createApiModule from '../createApiModule';

const exportUserModule = createApiModule({
  name: 'exportUsers',
  apiMethod: exportUsers,
  onSuccess: (data) =>
    downloadFileFromContent(
      data.content,
      'users.csv',
      'text/csv;charset=utf-8;'
    ),
  onError: () => ({
    message: 'Error exportando los usuarios/as',
  }),
  onValidationError: () => ({
    message: 'Error exportando los usuarios/as',
  }),
});

export const {
  start: exportUser,
  success: exportUserOk,
  error: exportUserKo,
} = exportUserModule.slice.actions;

export default exportUserModule;

import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const List = lazy(() => import('./List'));
const Update = lazy(() => import('./Update'));

const VecinosRoutes = [
  {
    path: 'vecinos',
    children: [
      {
        index: true,
        element: (
          <PrivateRoute allowedPermissions={['read_vecino']} component={List} />
        ),
      },
      {
        path: ':id/editar',
        element: (
          <PrivateRoute
            allowedPermissions={['update_vecino']}
            component={Update}
          />
        ),
      },
    ],
  },
];

export default VecinosRoutes;

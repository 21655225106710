import Stripe from 'stripe';
import { APIValidationError } from '../../types/api/api';
import { StripeSessionId, StripeSessionRequest } from '../../types/stripe';
import { Result, validatedApiCall } from '../api';

export const createSession = async (
  data: StripeSessionRequest
): Promise<Result<Stripe.Checkout.Session, APIValidationError>> => {
  return validatedApiCall<Stripe.Checkout.Session>(
    '/api/stripe/override/checkout_sessions',
    {
      method: 'POST',
      auth: false,
      body: JSON.stringify(data),
    }
  );
};

export const getSession = async (
  id: StripeSessionId
): Promise<Result<Stripe.Checkout.Session, APIValidationError>> => {
  return validatedApiCall<Stripe.Checkout.Session>(
    `/api/stripe/override/checkout_sessions/${id}`,
    {
      method: 'GET',
      auth: false,
    }
  );
};

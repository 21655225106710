import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Solicitud } from '../../types/solicitud/solicitud';
import { ISO8601DateString } from '../../types/standard';
import { LugarFallecimiento } from '../../types/comunicacion/lugarFallecimiento';
import { TipoComunicacion } from '../../types/comunicacion/tipoComunicacion';
import { Gender } from '../../types/users/gender';

export interface RegisterComunicacionDTO {
  nombre: string;
  apellido1: string;
  apellido2: string;
  tipo: TipoComunicacion;
  lugar_fallecimiento: LugarFallecimiento;
  genero: Gender;
  fecha_nacimiento: ISO8601DateString;
  residente: boolean;
}

export const registerComunicacion = async (
  data: RegisterComunicacionDTO
): Promise<Result<Solicitud, APIValidationError>> => {
  return validatedApiCall<Solicitud>(`/api/comunicaciones`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};

import { Result, validatedApiCall } from '../api';
import { User } from '../../types/users/users';
import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';

export const getUser = async (
  id: UUID
): Promise<Result<User, APIValidationError>> => {
  return validatedApiCall<User>(`/api/users/${id}`, {
    method: 'GET',
    auth: true,
  });
};

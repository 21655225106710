import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';
import { allPermissions } from '../../components/LoginRestrictions/LoginRestrictions';

const Register = lazy(() => import('./Register'));

const SesionesRoutes = [
  {
    path: 'sesiones/crear/:id',
    element: (
      <PrivateRoute allowedPermissions={allPermissions} component={Register} />
    ),
  },
];

export default SesionesRoutes;

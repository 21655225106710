import { getVecinos, VecinosDTO } from '../../api/vecinos/getVecinos';
import { GenericState } from '../../types/apiModule';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Vecino } from '../../types/users/users';
import createApiModule from '../createApiModule';

export interface VecinoModule extends GenericState<unknown> {
  data: VecinosDTO;
}

const initialState: VecinoModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Vecino[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const listVecinoModule = createApiModule({
  name: 'listVecinos',
  apiMethod: getVecinos,
  initialState,
  parse: (pueblos) => pueblos,
  onSuccess: () => {},
  onError: () => ({
    message: 'Error listando los vecinos',
  }),
  onValidationError: () => {},
});

export const {
  start: listVecinos,
  success: listVecinosOk,
  error: listVecinosKo,
  refresh: listVecinosRefresh,
  reset: listVecinosReset,
} = listVecinoModule.slice.actions;

export const { sagas } = listVecinoModule;

export default listVecinoModule;

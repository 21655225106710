import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const List = lazy(() => import('./List'));
const Post = lazy(() => import('./Post'));
const Update = lazy(() => import('./Update'));

const ActividadRoutes = [
  {
    path: 'actividades',
    children: [
      {
        index: true,
        element: (
          <PrivateRoute
            allowedPermissions={['read_actividad']}
            component={List}
          />
        ),
      },
      {
        path: 'nueva',
        element: (
          <PrivateRoute
            allowedPermissions={['create_actividad']}
            component={Post}
          />
        ),
      },
    ],
  },
  {
    path: 'actividad/:id/editar',
    element: (
      <PrivateRoute
        allowedRoles={['admin', 'agente_soporte']}
        component={Update}
      />
    ),
  },
];

export default ActividadRoutes;

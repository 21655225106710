import { Alert } from '../../types/alerts/alerts';
import { APIValidationError } from '../../types/api/api';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';
import { Result, validatedApiCall } from '../api';
import toQueryParams from '../../utils/toQueryParams';

export interface AlertsDTO {
  data: Alert[];
  links: LinksPage;
  meta: PageInfo;
}

export interface AlertURLDTO {
  read?: 1 | 0;
  page?: number;
  size?: number;
  sort?: 'created_at';
  order?: 'desc' | 'asc';
}

export const getAlerts = async (
  data: AlertURLDTO
): Promise<Result<AlertsDTO, APIValidationError>> => {
  return validatedApiCall<AlertsDTO>(
    `/api/alertas?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};

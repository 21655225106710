import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RememberPathState {
  path: string;
}

const initialState: RememberPathState = {
  path: '',
};

const rememberPathSlice = createSlice({
  name: 'rememberPath',
  initialState: initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    remember(state, action: PayloadAction<string>) {
      state.path = action.payload;
    },
  },
});

export const { reset, remember } = rememberPathSlice.actions;

export default rememberPathSlice;

import { lazy } from 'react';
import PublicRoute from '../../components/common/Router/PublicRoute/PublicRoute';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const DonacionExterna = lazy(() => import('./DonacionExterna'));
const DonacionResult = lazy(() => import('./DonacionResult'));
const List = lazy(() => import('./List'));

const DonacionRoutes = {
  public: [
    {
      path: 'donar',
      element: <PublicRoute component={DonacionExterna} />,
    },
    {
      path: 'donacion/result/:id',
      element: <PublicRoute component={DonacionResult} />,
    },
  ],
  private: [
    {
      path: 'donaciones',
      element: (
        <PrivateRoute allowedPermissions={['read_donacion']} component={List} />
      ),
    },
  ],
};

export default DonacionRoutes;

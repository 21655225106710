import { lazy } from 'react';
import PublicRoute from '../../components/common/Router/PublicRoute/PublicRoute';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';
import { allPermissions } from '../../components/LoginRestrictions/LoginRestrictions';

const SendPassword = lazy(() => import('./SendPassword'));
const ChangePassword = lazy(() => import('./ChangePassword'));
const NewPassword = lazy(() => import('./NewPassword'));

const PasswordRoutes = [
  {
    path: 'recordar_contrasena',
    children: [
      {
        index: true,
        element: <PublicRoute component={SendPassword} />,
      },
      {
        path: ':token/:email',
        element: <PublicRoute component={ChangePassword} />,
      },
    ],
  },
  {
    path: 'nueva_contrasena',
    element: (
      <PrivateRoute
        allowedPermissions={allPermissions}
        component={NewPassword}
      />
    ),
  },
];

export default PasswordRoutes;

import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { SchemaDescription, SchemaObjectDescription } from 'yup/lib/schema';

export default function useIsRequired<FormValues extends FieldValues>(
  name: string,
  schema: ObjectSchema<FormValues>
) {
  return useMemo(() => {
    const description = schema.describe();
    const nameParts = name.split('.');
    if (nameParts.length === 1) {
      if (name in description.fields) {
        const schemaFields = description.fields as {
          [k: string]: SchemaDescription;
        };
        const fieldDescription = schemaFields[name];
        return fieldDescription.tests.some((t) => t.name === 'required');
      }
      return false;
    }
    //nested validation
    let currentName = nameParts.shift();
    let currentFields: any = description.fields;
    while (
      nameParts.length > 0 &&
      currentName &&
      currentName in currentFields
    ) {
      currentFields = currentFields[currentName] as SchemaObjectDescription;
      currentName = nameParts.shift();
    }
    if (
      currentName &&
      currentFields.fields &&
      currentName in currentFields.fields
    ) {
      const schemaFields = currentFields.fields as {
        [k: string]: SchemaDescription;
      };
      const fieldDescription = schemaFields[currentName];
      return fieldDescription.tests.some((t) => t.name === 'required');
    }
    return false;
  }, [schema, name]);
}

import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const Show = lazy(() => import('./Show'));

const VoluntarioRoutes = [
  {
    path: 'voluntario/:id',
    element: (
      <PrivateRoute
        allowedRoles={['admin', 'agente_soporte']}
        component={Show}
      />
    ),
  },
];

export default VoluntarioRoutes;

import React from 'react';
import styles from './formLayout.module.scss';

export type Variant = 'profile' | 'security' | 'default';

interface FormLayoutProps
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  formTitle?: string;
  variant?: Variant;
  messageRequired?: boolean;
  showRequiredText?: boolean;
}

const theme = (variant: Variant): string => {
  switch (variant) {
    case 'profile':
      return styles.isProfile;
    case 'security':
      return styles.isSecurity;
    case 'default':
      return '';
  }
};

const FormLayout: React.FC<FormLayoutProps> = ({
  variant = 'default',
  formTitle,
  children,
  messageRequired,
  ...props
}) => (
  <section className={`${styles.root} ${theme(variant)}`}>
    {formTitle ? <h2 className={styles.formTitle}>{formTitle}</h2> : null}
    {messageRequired === undefined ||
      (messageRequired && messageRequired === true && (
        <p className={styles.description}>
          Recuerda que los datos marcados con (
          <span className={styles.asterisk}>*</span>) son obligatorios.
        </p>
      ))}
    <form className={styles.form} {...props}>
      {children}
    </form>
  </section>
);

export default FormLayout;

import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const Alerts = lazy(() => import('./Alerts'));

const AlertsRoutes = [
  {
    path: 'notificaciones',
    element: (
      <PrivateRoute
        allowedPermissions={['read_notification']}
        component={Alerts}
      />
    ),
  },
];

export default AlertsRoutes;

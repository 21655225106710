import { PayloadAction } from '@reduxjs/toolkit';
import { changePueblo as changePuebloAction } from '../../api/vecinos/changePueblo';
import { GenericState } from '../../types/apiModule';
import { User } from '../../types/users/users';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listVecinosRefresh } from './listVecinosModule';
export interface changePuebloState extends GenericState<unknown> {
  active: boolean;
  user: User;
}
const initialState: changePuebloState = {
  errors: null,
  data: [],
  loading: false,
  active: false,
  user: {} as User,
};

const changePuebloModule = createApiModule({
  name: 'changePueblo',
  initialState,
  apiMethod: changePuebloAction,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'El vecino/a se ha cambiado de pueblo.',
    }),
    listVecinosRefresh(),
  ],
  onError: () => ({
    message: 'Error cambiando el vecino/a de pueblo',
  }),
  onValidationError: () => ({
    message: 'Error cambiando el vecino/a de pueblo',
  }),
  reducers: {
    show(state, action: PayloadAction<User>) {
      state.active = true;
      state.user = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: changePueblo,
  success: changePuebloOk,
  error: changePuebloKo,
  hide,
  show,
} = changePuebloModule.slice.actions;

export default changePuebloModule;

import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';
import { APIValidationError } from '../../types/api/api';
import { Entidad } from '../../types/entidad/Entidad';

export const getEntidadById = async (
  id: UUID
): Promise<Result<Entidad, APIValidationError>> => {
  return validatedApiCall<Entidad>(`/api/entidades/${id}`, {
    method: 'GET',
    auth: true,
  });
};

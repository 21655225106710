import { lazy } from 'react';
import PublicRoute from '../../components/common/Router/PublicRoute/PublicRoute';

const Login = lazy(() => import('./Login'));
const LoginRoutes = [
  {
    path: 'login',
    element: <PublicRoute component={Login} />,
  },
];

export default LoginRoutes;

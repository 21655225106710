import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const List = lazy(() => import('./List'));
const Register = lazy(() => import('./Register'));

const PuebloRoutes = [
  {
    path: 'pueblos',
    element: (
      <PrivateRoute allowedPermissions={['read_pueblo']} component={List} />
    ),
  },
  {
    path: 'pueblo/nuevo',
    element: (
      <PrivateRoute
        allowedPermissions={['create_pueblo']}
        component={Register}
      />
    ),
  },
];

export default PuebloRoutes;

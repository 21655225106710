import { updateEntidad } from '../../api/entidades/updateEntidad';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { resetEntidad } from './entidadModule';

const updateEntidadModule = createApiModule({
  name: 'updateEntidad',
  apiMethod: updateEntidad,
  onSuccess: (entidad) => [
    resetEntidad(),
    showSnackBar({
      severity: 'success',
      message: `La entidad ${entidad.nombre} se ha modificado correctamente.`,
      path: '/entidades',
    }),
  ],

  onError: () => ({
    message: 'Error modificando la entidad',
  }),
  onValidationError: (error) => ({
    message: error.message || 'Error modificando la entidad',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateEntidadModule.slice.actions;

export const { sagas } = updateEntidadModule;

export default updateEntidadModule;

import { PayloadAction } from '@reduxjs/toolkit';
import { deletePueblo } from '../../api/pueblos/deletePueblo';
import { GenericState } from '../../types/apiModule';
import { Pueblo } from '../../types/pueblos/pueblo';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listPueblosRefresh } from './listPuebloModule';
export interface deletePuebloState extends GenericState<unknown> {
  active: boolean;
  data: Pueblo[];
  selectedPueblo: Pueblo | null;
}
const initialState: deletePuebloState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  selectedPueblo: null,
};

const deletePuebloModule = createApiModule({
  name: 'deletePueblo',
  initialState,
  apiMethod: deletePueblo,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'El municipio se ha eliminado correctamente.',
    }),
    listPueblosRefresh(),
  ],
  onError: () => ({
    message: 'Error borrando el municipio',
  }),
  onValidationError: () => ({
    message: 'Error borrando el municipio',
  }),
  reducers: {
    show(state, action: PayloadAction<Pueblo>) {
      state.active = true;
      state.selectedPueblo = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: del,
  success: delOk,
  error: delKo,
  hide,
  show,
} = deletePuebloModule.slice.actions;

export default deletePuebloModule;

import { postActividad } from '../../api/actividad/postActividad';
import createApiModule from '../createApiModule';

const postActividadModule = createApiModule({
  name: 'postActividad',
  apiMethod: postActividad,
  onSuccess: () => ({
    message: `La actividad se ha creado correctamente.`,
    path: '/actividades',
  }),
  onError: () => ({
    message: 'Error creando la activdad',
  }),
  onValidationError: () => ({
    message: 'Error creando la actividad',
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = postActividadModule.slice.actions;

export const { sagas } = postActividadModule;

export default postActividadModule;

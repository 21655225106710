import { donacionExterna } from '../../api/donar/donacionExterna';
import createApiModule from '../createApiModule';

const donacionExternaModule = createApiModule({
  name: 'donacionExterna',
  apiMethod: donacionExterna,
  initialData: null,
  parse: (donacion) => donacion,
  onSuccess: () => {},
  onError: () => ({
    message: 'Error creando la donación',
  }),
  onValidationError: () => ({
    message: 'Error creando la donación',
  }),
});

export const {
  start: createDonacionExterna,
  success: createDonacionExternaOk,
  error: createDonacionExternaKo,
  reset: clearDonacionExterna,
} = donacionExternaModule.slice.actions;

export default donacionExternaModule;

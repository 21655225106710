import { deleteAlerts as callDeleteAlerts } from '../../api/alerts/deleteAlerts';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listAlerts } from './alertsModule';

const deleteAlertsModule = createApiModule({
  name: 'deleteAlerts',
  apiMethod: callDeleteAlerts,
  parse: (response) => response.data,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'Las alertas se han eliminado',
    }),
    listAlerts({ page: 1, size: 10 }),
  ],
  onError: () => ({
    message: 'No hemos podido borrar las alertas',
  }),
  onValidationError: () => ({
    message: 'No hemos podido borrar las alertas',
  }),
});

export const {
  start: deleteAlerts,
  success: deleteAlertsOk,
  error: deleteAlertsKo,
} = deleteAlertsModule.slice.actions;

export const { sagas } = deleteAlertsModule;

export default deleteAlertsModule;

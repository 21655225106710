import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentIntentResult } from '@stripe/stripe-js';
import {
  DonacionUserDTO,
  MakeDonacionUserDTO,
} from '../../api/donaciones/registerDonacionUser';
import { APIError } from '../../types/api/api';
import { DonacionStatus } from '../../types/donaciones/donacionStatus';

export interface RegisterDonacionSlice {
  make_donacion: MakeDonacionUserDTO | null;
  donacion_loading: boolean;
  donacion_errors: APIError | null;
  confirm: PaymentIntentResult | null;
}

const initialState: RegisterDonacionSlice = {
  make_donacion: null,
  donacion_loading: false,
  donacion_errors: null,
  confirm: null,
};

const registerDonacionSlice = createSlice({
  name: 'registerDonacion',
  initialState: initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    registerDonacion(state, action: PayloadAction<DonacionUserDTO>) {
      state.make_donacion = null;
      state.donacion_loading = true;
      state.donacion_errors = null;
      state.confirm = null;
    },
    registerDonacionOk(state, action: PayloadAction<MakeDonacionUserDTO>) {
      state.make_donacion = action.payload;
      state.donacion_loading = false;
    },
    registerDonacionMustConfirm(
      state,
      action: PayloadAction<MakeDonacionUserDTO>
    ) {
      state.make_donacion = action.payload;
    },
    registerDonacionKo(state, action: PayloadAction<APIError>) {
      state.donacion_loading = false;
      state.donacion_errors = action.payload;
    },
    registerConfirmOk(state, action: PayloadAction<DonacionStatus>) {
      state.donacion_loading = false;
      if (state.make_donacion) {
        state.make_donacion.status = action.payload;
      }
    },
    registerConfirmKo(state, action: PayloadAction<APIError>) {
      state.donacion_loading = false;
      state.donacion_errors = action.payload;
    },
  },
});

export const {
  reset,
  registerDonacion,
  registerDonacionMustConfirm,
  registerDonacionOk,
  registerDonacionKo,
  registerConfirmKo,
  registerConfirmOk,
} = registerDonacionSlice.actions;

export default registerDonacionSlice;

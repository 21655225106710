import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import {
  AuthDTO,
  ChangePasswordDTO,
  LoginDTO,
  ChangeAvatarDTO,
} from '../../types/auth/auth';
import { Permission } from '../../types/auth/permissions';
import { UserStates } from '../../types/users/state';
import { User } from '../../types/users/users';

export type AuthState = {
  loading: boolean;
  errors: APIError | null;
  user: null | {
    permissions: Permission[];
    data: User;
  };
};

export const initialState: AuthState = {
  loading: false,
  errors: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, _action: PayloadAction<LoginDTO>) {
      state.loading = true;
      state.errors = null;
      state.user = null;
    },
    loginOk(state, action: PayloadAction<AuthDTO>) {
      const { user, permissions } = action.payload;
      state.loading = false;
      state.errors = null;
      state.user = {
        permissions,
        data: user,
      };
    },
    loginKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
      state.user = null;
    },
    logout() {
      return { ...initialState };
    },
    editProfile(state, _action: PayloadAction<User>) {
      state.loading = true;
      state.errors = null;
    },
    editProfileOk(state, action: PayloadAction<User>) {
      if (state.user) state.user.data = action.payload;
      state.loading = false;
      state.errors = null;
    },
    editProfileKo(state, action: PayloadAction<APIError>) {
      if (state.user) state.user.data = {} as User;
      state.loading = false;
      state.errors = action.payload;
    },
    addUserState(state, _action: PayloadAction<UserStates>) {
      state.loading = true;
      state.errors = null;
    },
    addUserStateOk(state, action: PayloadAction<User>) {
      if (state.user) state.user.data = action.payload;
      state.loading = false;
      state.errors = null;
    },
    addUserStateKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },

    changePassword(state, _action: PayloadAction<ChangePasswordDTO>) {
      state.loading = true;
      state.errors = null;
    },
    changePasswordOk(state) {
      state.loading = false;
      state.errors = null;
    },
    changePasswordKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
    changeAvatar(state, _action: PayloadAction<ChangeAvatarDTO>) {
      state.loading = true;
      state.errors = null;
    },
    changeAvatarOk(state, action: PayloadAction<User>) {
      state.loading = false;
      state.errors = null;
      if (!state.user)
        throw new Error('Updated avatar of a non logged in user?');
      state.user.data = action.payload;
    },
    changeAvatarKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  login,
  loginOk,
  loginKo,
  logout,
  editProfile,
  changePassword,
  changePasswordOk,
  changePasswordKo,
  changeAvatar,
  changeAvatarOk,
  changeAvatarKo,
  addUserState,
} = authSlice.actions;

export default authSlice;

import { APIValidationError } from '../../types/api/api';
import { Result, validatedApiCall } from '../api';

export const bajaVecino = async (): Promise<
  Result<boolean, APIValidationError>
> => {
  return validatedApiCall<boolean>('/api/vecinos', {
    method: 'DELETE',
    auth: true,
  });
};

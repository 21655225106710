import { lazy } from 'react';
import PublicRoute from '../../components/common/Router/PublicRoute/PublicRoute';

const Register = lazy(() => import('./Register'));
const RegisterSuccess = lazy(() => import('./RegisterSuccess'));

const RegisterRoutes = [
  {
    path: 'registro',
    children: [
      {
        index: true,
        element: <PublicRoute component={Register} />,
      },
      {
        path: 'success/:id',
        element: <PublicRoute component={RegisterSuccess} />,
      },
    ],
  },
];

export default RegisterRoutes;

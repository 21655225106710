import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectHasAnyPermission,
  selectHasSomeRole,
  selectIsLoggedIn,
} from '../../../../store/auth/selectors';
import { RootState } from '../../../../store/store';
import { Permission } from '../../../../types/auth/permissions.d';
import { Role } from '../../../../types/auth/roles.d';
import { Navigate, useLocation } from 'react-router-dom';

interface PrivateRouteProps {
  component: React.FC;
  allowedRoles?: Role[];
  allowedPermissions?: Permission[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  allowedRoles,
  allowedPermissions,
}) => {
  const location = useLocation();
  const { canAccessRoute, isAuthenticated } = useSelector(
    (state: RootState) => ({
      canAccessRoute: allowedPermissions
        ? selectHasAnyPermission(state, allowedPermissions)
        : selectHasSomeRole(state, allowedRoles || []),
      isAuthenticated: selectIsLoggedIn(state),
      state,
    })
  );

  return canAccessRoute ? (
    <Component />
  ) : isAuthenticated ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;

import { getUser as getUsr } from '../../api/users/getUser';
import createApiModule from '../createApiModule';

const userModule = createApiModule({
  name: 'user',
  apiMethod: getUsr,
  initialData: null,
  parse: (user) => user,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar el/la usuario/a',
  }),
  onValidationError: () => ({
    message: 'No hemos podido cargar el/la usuario/a',
  }),
});

export const {
  start: getUser,
  success: getUserOk,
  error: getUserKo,
  reset: resetUser,
} = userModule.slice.actions;

export default userModule;

import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Voluntario } from '../../types/voluntarios/Voluntario.d';

export const getVoluntarios = async (): Promise<
  Result<Voluntario[], APIValidationError>
> => {
  return validatedApiCall<Voluntario[]>(`/api/voluntarios`, {
    method: 'GET',
    auth: true,
  });
};

import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DefaultPaymentMethodDTO,
  getCustomer,
  setDefaultPaymentMethod,
} from '../../../api/stripe/override/customers';
import { Await } from '../../../types/api/api';
import { selectAuthId } from '../../auth/selectors';
import customerSlice from './customerSlice';
import { isApiError } from '../../../api/api';

function* loadCustomer(): Generator<any, void, any> {
  try {
    const result = (yield call(getCustomer)) as Await<
      ReturnType<typeof getCustomer>
    >;
    switch (result.type) {
      case 'ok':
        yield put(customerSlice.actions.loadCustomerOk(result.value));
        return;
      case 'validation-error':
        yield put(customerSlice.actions.loadCustomerKo(result.value));
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(customerSlice.actions.loadCustomerKo(e));
    }
    throw e;
  }
}

function* updateDefaultPaymentMethod(
  action: PayloadAction<string>
): Generator<any, void, any> {
  try {
    const dto: DefaultPaymentMethodDTO = {
      user_id: yield select(selectAuthId),
      payment_method_id: action.payload,
    };
    const result = (yield call(setDefaultPaymentMethod, dto)) as Await<
      ReturnType<typeof setDefaultPaymentMethod>
    >;
    switch (result.type) {
      case 'ok':
        yield put(
          customerSlice.actions.updateDefaultPaymentMethodOk(result.value)
        );
        return;
      case 'validation-error':
        yield put(
          customerSlice.actions.updateDefaultPaymentMethodKo(result.value)
        );
        return;
    }
  } catch (e) {
    if (isApiError(e)) {
      yield put(customerSlice.actions.updateDefaultPaymentMethodKo(e));
    }
    throw e;
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    customerSlice.actions.loadCustomer.type,
    loadCustomer
  ),
  takeLatest<PayloadAction<never>>(
    customerSlice.actions.updateDefaultPaymentMethod.type,
    updateDefaultPaymentMethod
  ),
];

export default sagas;

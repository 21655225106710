import { updateUser } from '../../api/users/updateUser';
import createApiModule from '../createApiModule';

const updateUserModule = createApiModule({
  name: 'updateUser',
  apiMethod: updateUser,
  onSuccess: (user) => ({
    message: `El/La usuario/a ${user.nombre} ${user.apellido1} ${user.apellido2} se ha modificado correctamente.`,
    path: '/usuarios',
  }),
  onError: () => ({
    message: 'Error al modificar el/la usuario/a',
  }),
  onValidationError: () => ({
    message: 'Error al modficar el/la usuario/a',
  }),
});

export const {
  start: update,
  success: updateOk,
  error: updateKo,
} = updateUserModule.slice.actions;

export default updateUserModule;

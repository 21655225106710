import React from 'react';
import ChangeEntidadPrincipalPueblo from './ChangeEntidadPrincipalPueblo/ChangeEntidadPrincipalPueblo';
import ChangePuebloVecino from './ChangePuebloVecino/ChangePuebloVecino';
import DeleteActividad from './DeleteActividad/DeleteActividad';
import DeleteEntidad from './DeleteEntidad/DeleteEntidad';
import DeletePueblo from './DeletePueblo/DeletePueblo';
import DeleteUser from './DeleteUser/DeleteUser';
import DeleteVecino from './DeleteVecino/DeleteVecino';
import DeleteVoluntario from './DeleteVoluntario/DeleteVoluntario';

const PopupActions = () => {
  return (
    <>
      <DeletePueblo />
      <DeleteEntidad />
      <DeleteUser />
      <ChangePuebloVecino />
      <DeleteVecino />
      <ChangeEntidadPrincipalPueblo />
      <DeleteActividad />
      <DeleteVoluntario />
    </>
  );
};

export default PopupActions;

import { getVoluntarioEstado as callGetVoluntarioEstado } from '../../api/voluntarios/getVoluntarioEstado';
import createApiModule from '../createApiModule';
import listVoluntariosModule from './ListVoluntariosModule';

const voluntarioEstadoModule = createApiModule({
  name: 'voluntarioEstado',
  apiMethod: callGetVoluntarioEstado,
  parse: (estado) => estado,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido recuperar tu estado de voluntariado',
  }),
});

export const {
  start: getVoluntarioEstado,
  success: getVoluntarioEstadoOk,
  error: getVoluntarioEstadoKo,
  loading: getVoluntarioEstadoLoading,
} = voluntarioEstadoModule.slice.actions;

export const { sagas } = listVoluntariosModule;

export default voluntarioEstadoModule;

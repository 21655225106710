import { sendPassword } from '../../api/forgotPassword/sendPassword';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';

const sendPasswordModule = createApiModule({
  name: 'sendPassword',
  apiMethod: sendPassword,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'Se ha enviado un email con las instrucciones.',
      path: '/login',
    }),
  ],
  onError: () => ({
    message: 'El email no existe',
  }),
  onValidationError: () => ({
    message: 'El email no existe',
  }),
});

export const {
  start: send,
  success: sendPasswordOk,
  error: sendPasswordKo,
} = sendPasswordModule.slice.actions;

export default sendPasswordModule;

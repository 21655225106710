import { APIValidationError } from '../../types/api/api';
import { Result, validatedApiCall } from '../api';

export const bajaVoluntario = async (): Promise<
  Result<boolean, APIValidationError>
> => {
  return validatedApiCall<boolean>(`/api/vecinos/baja_voluntario`, {
    method: 'GET',
    auth: true,
  });
};

import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';

export const setPrincipalEntidad = async (
  data: UUID
): Promise<Result<boolean, APIValidationError>> => {
  return validatedApiCall<boolean>(`/api/entidades/${data}/entidad_principal`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });
};

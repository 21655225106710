import React from 'react';
import { connect } from 'react-redux';
import {
  hide,
  del as deletePueblo,
} from '../../../store/pueblos/deletePuebloModule';

import { AppDispatch, RootState } from '../../../store/store';
import { UUID } from '../../../types/standard';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';
import { Pueblo } from '../../../types/pueblos/pueblo';
import {
  selectDeletePueblo,
  selectDeletePuebloActive,
  selectDeletePuebloLoading,
} from '../../../store/pueblos/selectors';

interface DeletePuebloProps {
  pueblo: Pueblo | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  deletePueblo: (id: UUID) => void;
}

const DeletePueblo: React.FC<DeletePuebloProps> = ({
  pueblo,
  active,
  loading,
  closeModal,
  deletePueblo,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {pueblo && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar municipio
            </h1>
            <p className={styles.description}>
              ¿Estás seguro de querer eliminar el municipio?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p>
                  <span className={styles.appointmentSection}>Nombre:</span>
                  {pueblo.poblacion}
                </p>
                <p>
                  <span className={styles.appointmentSection}>
                    Código Postal:
                  </span>
                  {pueblo.codigo_postal}
                </p>
                <p>
                  <span className={styles.appointmentSection}>Provinicia:</span>
                  {pueblo.provincia}
                </p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                variant="positive"
                onClick={() => {
                  deletePueblo(pueblo.id);
                  closeModal();
                }}
                data-testid="submit_button"
              >
                Eliminar
              </Button>
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeletePueblo = connect(
  (state: RootState) => ({
    pueblo: selectDeletePueblo(state),
    active: selectDeletePuebloActive(state),
    loading: selectDeletePuebloLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    deletePueblo: (payload: UUID) => dispatch(deletePueblo(payload)),
    closeModal: () => dispatch(hide()),
  })
)(DeletePueblo);

export default renderWhen(ConnectedDeletePueblo, selectDeletePuebloActive);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Stripe from 'stripe';
import { APIError } from '../../../types/api/api';
import { StripeSessionId, StripeSessionRequest } from '../../../types/stripe';

export type CheckoutSessionState = {
  loading: boolean;
  session: Stripe.Checkout.Session | null;
  error: APIError | null;
};

const initialState: CheckoutSessionState = {
  loading: false,
  session: null,
  error: null,
};

const checkoutSessionSlice = createSlice({
  name: 'checkoutSession',
  initialState: initialState,
  reducers: {
    createSession(state, action: PayloadAction<StripeSessionRequest>) {
      state.loading = true;
      state.session = null;
      state.error = null;
    },
    createSessionOk(state, action: PayloadAction<Stripe.Checkout.Session>) {
      state.loading = false;
      state.session = action.payload;
      state.error = null;
    },
    createSessionKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.session = null;
      state.error = action.payload;
    },
    loadSession(state, action: PayloadAction<StripeSessionId>) {
      state.loading = true;
      state.session = null;
      state.error = null;
    },
    loadSessionOk(state, action: PayloadAction<Stripe.Checkout.Session>) {
      state.loading = false;
      state.session = action.payload;
      state.error = null;
    },
    loadSessionKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.session = null;
      state.error = action.payload;
    },
  },
});

export const {
  createSession,
  createSessionOk,
  createSessionKo,
  loadSession,
  loadSessionOk,
  loadSessionKo,
} = checkoutSessionSlice.actions;

export default checkoutSessionSlice;

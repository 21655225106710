import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { Solicitud } from '../../types/solicitud/solicitud';
import { TipoSolicitud } from '../../types/solicitud/tipoSolicitud';

export interface SendSolicitudDTO {
  tipo: TipoSolicitud | '';
}

export const sendSolicitud = async (data: {
  data: SendSolicitudDTO;
}): Promise<Result<Solicitud, APIValidationError>> => {
  return validatedApiCall<Solicitud>(`/api/solicitudes`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data.data),
  });
};

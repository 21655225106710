import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';

export const deleteUser = async (
  id: UUID | undefined
): Promise<Result<boolean, APIValidationError>> => {
  return validatedApiCall<boolean>(`/api/users/${id}`, {
    method: 'DELETE',
    auth: true,
  });
};

import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';
import { allPermissions } from '../../components/LoginRestrictions/LoginRestrictions';

const List = lazy(() => import('./List'));
const Register = lazy(() => import('./Register'));

const MisDonacionesRoutes = [
  {
    path: 'mis-donaciones',
    element: (
      <PrivateRoute
        allowedPermissions={['read_donacion_own']}
        component={List}
      />
    ),
  },
  {
    path: 'mis-donaciones/nueva',
    element: (
      <PrivateRoute allowedPermissions={allPermissions} component={Register} />
    ),
  },
];

export default MisDonacionesRoutes;

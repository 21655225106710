import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';
import { Valoracion } from '../../types/valoracion/valoracion';
import { Result, validatedApiCall } from '../api';

export const registerValoracion = async (
  solicitud_id: UUID
): Promise<Result<Valoracion, APIValidationError>> => {
  return validatedApiCall<Valoracion>(
    `/api/solicitudes/${solicitud_id}/valoracion`,
    {
      method: 'POST',
      auth: true,
    }
  );
};

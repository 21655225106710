import { RootState } from '../store';

/******************** LIST ACTIVIDAD *****************************/
export const selectListActividad = (state: RootState) => state.listActividad;

export const selectListActividades = (state: RootState) =>
  selectListActividad(state)?.data.data;

export const selectListAtividadPageInfo = (state: RootState) =>
  selectListActividad(state).data.meta;

export const selectListActividadLoading = (state: RootState) =>
  selectListActividad(state)?.loading;

export const selectListActividadErrors = (state: RootState) =>
  selectListActividad(state)?.errors;

/******************** POST ACTIVIDAD *****************************/
export const selectPostActividad = (state: RootState) => state.postActividad;

export const selectPostActividadLoading = (state: RootState) =>
  selectPostActividad(state)?.loading;

export const selectPostActividadErrors = (state: RootState) =>
  selectPostActividad(state)?.errors;

/******************** DELETE ACTIVIDAD *****************************/
export const selectDeleteActividad = (state: RootState) =>
  state.deleteActividad.actividad;

export const selectDeleteActividadActive = (state: RootState) =>
  state.deleteActividad.active;

export const selectDeleteActividadLoading = (state: RootState) =>
  state.deleteActividad.loading;

/******************** UPDATE ACTIVIDAD *****************************/
export const selectUpdateActividad = (state: RootState) =>
  state.updateActividad;

export const selectUpdateActividadErrors = (state: RootState) =>
  selectUpdateActividad(state).errors;

export const selectUpdateActividadLoading = (state: RootState) =>
  selectUpdateActividad(state).loading;

/******************** GET ACTIVIDAD *****************************/
export const selectActividad = (state: RootState) => state.actividad.data;

export const selectActividadErrors = (state: RootState) =>
  state.actividad.errors;

export const selectActividadLoading = (state: RootState) =>
  state.actividad.loading;

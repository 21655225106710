import { lazy } from 'react';
import PrivateRoute from '../../components/common/Router/PrivateRoute/PrivateRoute';

const VecinoProfile = lazy(() => import('./VecinoProfile'));

const ProfileRoutes = [
  {
    path: 'perfil/*',
    element: (
      <PrivateRoute
        allowedRoles={['admin', 'vecino']}
        component={VecinoProfile}
      />
    ),
  },
];

export default ProfileRoutes;

import { APIValidationError } from '../../types/api/api';
import { VoluntarioEstado } from '../../types/voluntarios/voluntarioEstado';
import { Result, validatedApiCall } from '../api';

export interface EstadoVoluntario {
  estado: VoluntarioEstado;
}

export const getVoluntarioEstado = async (): Promise<
  Result<EstadoVoluntario, APIValidationError>
> => {
  return validatedApiCall<EstadoVoluntario>(`/api/vecinos/estado_voluntario`, {
    method: 'GET',
    auth: true,
  });
};

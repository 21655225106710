import { registerSesion } from '../../api/sesiones/registerSesion';
import createApiModule from '../createApiModule';

const registerSesionModule = createApiModule({
  name: 'registerSesion',
  apiMethod: registerSesion,
  onSuccess: (sesion) => ({
    message: 'Se ha registrado la sesión',
    path: '/acompanamiento',
  }),
  onError: () => ({
    message: 'Se ha producido un error registrando la sesion',
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerSesionModule.slice.actions;

export const { sagas } = registerSesionModule;

export default registerSesionModule;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { hide, changePueblo } from '../../../store/vecinos/changePuebloModule';
import * as yup from 'yup';

import { AppDispatch, RootState } from '../../../store/store';
import Button from '../../common/Buttons/Button/Button';
import Loading from '../../common/Loading/Loading';
import Modal from '../../common/Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../common/Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

import { User } from '../../../types/users/users';
import {
  selectChangePuebloVecinoActive,
  selectChangePuebloVecinoLoading,
  selectChangePuebloVecinoUser,
  selectChangePuebloVecinoErrors,
} from '../../../store/vecinos/selectors';
import { ChangePuebloDTO } from '../../../api/vecinos/changePueblo';
import FormLayout from '../../common/Layouts/FormLayout/FormLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import useOurForm from '../../../hooks/useOurForm';
import ValidatedInput from '../../common/Form/ValidatedInput/ValidatedInput';
import {
  listPueblos,
  listPueblosReset,
} from '../../../store/pueblos/listPuebloModule';
import { GetPuebloDTO } from '../../../api/pueblos/getPueblos';
import {
  selectPueblos,
  selectPueblosLoading,
} from '../../../store/pueblos/selectors';
import { Pueblo } from '../../../types/pueblos/pueblo';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import { APIError } from '../../../types/api/api';
import GlobalError from '../../common/Form/GlobalError/GlobalError';
import ValidatedSelect from '../../common/Form/ValidatedSelect/ValidatedSelect';

const changePuebloVecinoSchema = yup
  .object()
  .shape({
    calle: yup.string().required('Introduce la calle'),
    numero: yup.string().required('Introduce el número'),
    pueblo_id: yup.string().required('Introduce un municipio'),
  })
  .defined();

interface ChangePuebloVecinoProps {
  user: User;
  pueblos: Pueblo[];
  serverErrors: APIError | null;
  active: boolean;
  loading: boolean;
  loadingPueblos: boolean;
  closeModal: () => void;
  changePueblo: (id: ChangePuebloDTO) => void;
  loadPueblos: (id: GetPuebloDTO) => void;
  resetPueblos: () => void;
}

const ChangePuebloVecino: React.FC<ChangePuebloVecinoProps> = ({
  user,
  pueblos,
  serverErrors,
  active,
  loading,
  loadingPueblos,
  closeModal,
  changePueblo,
  loadPueblos,
  resetPueblos,
}) => {
  const { handleSubmit, register, errors, setError, formState } =
    useOurForm<ChangePuebloDTO>({
      mode: 'onSubmit',
      resolver: yupResolver(changePuebloVecinoSchema),
    });

  const changePuebloSubmit = (data: ChangePuebloDTO) => {
    data.user_id = user.id ?? '';
    changePueblo(data);
  };

  useEffect(() => {
    if (pueblos.length === 0) {
      loadPueblos({ size: 1000 });
    }
  }, [pueblos, loadPueblos, resetPueblos]);

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  // 'active' controls if the modal is open or close
  if (!active) return null;

  const nombrePueblo =
    user.roles && user.roles.vecino && user.roles.vecino.pueblo
      ? user.roles.vecino.pueblo.poblacion
      : '';

  const changePuebloVecinoSubmit = (data: ChangePuebloDTO) => {
    changePuebloSubmit(data);
    closeModal();
  };

  return (
    <>
      {user && (
        <Modal closeModal={closeModal} variant="large">
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Cambiar de municipio
            </h1>
            <p className={styles.description}>
              {`El vecino/a ${user.nombre} ${user.apellido1} ${user.apellido2} está en el municipio ${nombrePueblo}`}
            </p>
            <div className={styles.appointmentWrapper}>
              <FormLayout
                variant={'profile'}
                onSubmit={handleSubmit(changePuebloVecinoSubmit)}
              >
                <ValidatedSelect
                  schema={changePuebloVecinoSchema}
                  errors={errors}
                  label="Municipio"
                  placeholder="Selecciona un municipio"
                  select={{
                    name: 'pueblo_id',
                    ref: register,
                  }}
                >
                  {!loadingPueblos &&
                    pueblos.map((pueblo) => (
                      <option key={pueblo.id} value={pueblo.id}>
                        {pueblo.poblacion}
                      </option>
                    ))}
                </ValidatedSelect>
                <ValidatedInput
                  schema={changePuebloVecinoSchema}
                  errors={errors}
                  label="Calle"
                  input={{
                    name: 'calle',
                    type: 'text',
                    ref: register,
                  }}
                />
                <ValidatedInput
                  schema={changePuebloVecinoSchema}
                  errors={errors}
                  label="Número"
                  input={{
                    name: 'numero',
                    type: 'text',
                    ref: register,
                  }}
                />
                <ValidatedInput
                  schema={changePuebloVecinoSchema}
                  errors={errors}
                  label="Piso"
                  input={{
                    name: 'piso',
                    type: 'text',
                    ref: register,
                  }}
                />
                <ButtonsGroup variant="profile">
                  <React.Fragment>
                    <Button
                      type="button"
                      variant="negative"
                      onClick={closeModal}
                    >
                      Cerrar
                    </Button>
                    <Button variant="positive">Guardar</Button>
                  </React.Fragment>
                </ButtonsGroup>
              </FormLayout>
            </div>
            {/* Server-side global error */}
            {globalError && <GlobalError message={globalError} />}

            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedChangePuebloVecino = connect(
  (state: RootState) => ({
    user: selectChangePuebloVecinoUser(state),
    active: selectChangePuebloVecinoActive(state),
    loading: selectChangePuebloVecinoLoading(state),
    serverErrors: selectChangePuebloVecinoErrors(state),
    pueblos: selectPueblos(state),
    loadingPueblos: selectPueblosLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    changePueblo: (payload: ChangePuebloDTO) => dispatch(changePueblo(payload)),
    closeModal: () => dispatch(hide()),
    loadPueblos: (payload: GetPuebloDTO) => dispatch(listPueblos(payload)),
    resetPueblos: () => dispatch(listPueblosReset()),
  })
)(ChangePuebloVecino);

export default renderWhen(
  ConnectedChangePuebloVecino,
  selectChangePuebloVecinoActive
);

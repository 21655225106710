import { Voluntario } from '../../types/voluntarios/Voluntario';
import createApiModule from '../createApiModule';
import { showVoluntario as showVoluntarioApi } from '../../api/voluntarios/showVoluntario';
import { GenericState } from '../../types/apiModule';

export interface showVoluntarioState extends GenericState<Voluntario | null> {}

const initialState: showVoluntarioState = {
  errors: null,
  loading: false,
  data: null,
};

const showVoluntarioModule = createApiModule({
  name: 'showVoluntario',
  apiMethod: showVoluntarioApi,
  initialState,
  parse: (voluntario) => voluntario as Voluntario | null,
  onSuccess: () => {},
  onError: () => ({
    message: 'Error cargando el voluntario',
  }),
  onValidationError: () => {},
});

export const {
  start: showVoluntario,
  success: showVoluntarioOk,
  error: showVoluntarioKo,
  refresh: showVoluntarioRefresh,
  reset: showVoluntarioReset,
} = showVoluntarioModule.slice.actions;

export const { sagas } = showVoluntarioModule;

export default showVoluntarioModule;
